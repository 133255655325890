<template>
  <div class="map">
    <b-container>
      <b-tabs class="maps-tabs">
        <b-tab class="map__pane" title="Воронеж" active>
          <div class="map__info">
            <div class="map__item">
              <img src="@/assets/images/address.svg" alt="Адрес">
                <div class="cw">
                  <span>Адрес:</span>
                  <p>г. Воронеж, ул. Московский проспект 67, 2 эт.</p>
                </div>
            </div>
            <div class="map__item">
              <img src="@/assets/images/time.svg" alt="Режим работы">
              <div class="cw">
                <span>Режим работы:</span>
                <p>Пн-пт: 9:00-19:00 <br>| Сб-вс: 9:00-17:00</p>
              </div>
            </div>
            <div class="map__item">
              <img src="@/assets/images/phone.svg" alt="Телефон">
              <span
                >Номер телефона: <br /><a href="tel:+79003045191"
                  >+7 (900) 304-51-91</a
                ></span
              >
            </div>
          </div>
          <a class="show-map" href="javascript:void(0)" @click="openMap('vrn')"><span>{{ isOpenMap === 'vrn' ? 'Скрыть' : 'Показать' }} карту</span> <img src="@/assets/images/map-mobile.svg" alt=""></a>
          <div id="mapVRN" class="map-content d-none d-sm-block" :class="{'d-block': isOpenMap === 'vrn'}"></div>
        </b-tab>
        <b-tab class="map__pane" title="Лиски">
          <div class="map__info">
            <div class="map__item">
              <img src="@/assets/images/address.svg" alt="Адрес">
                <div class="cw">
                  <span>Адрес:</span>
                  <p>г. Лиски, ул. Матросова, 2Б</p>
                </div>
            </div>
            <div class="map__item">
              <img src="@/assets/images/time.svg" alt="Режим работы">
                <div class="cw">
                  <span>Режим работы:</span>
                  <p>Пн-пт: 8:00-17:00 | Сб-вс: 8:00-16:00</p>
                </div>
            </div>
            <div class="map__item">
              <img src="@/assets/images/phone.svg" alt="Телефон">
              <span
                >Номер телефона: <br /><a href="tel:+79529517091"
                  >+7 (952) 951-70-91</a
                ></span
              >
            </div>
          </div>
          <a class="show-map" href="javascript:void(0)" @click="openMap('lsk')"><span>{{ isOpenMap === 'lsk' ? 'Скрыть' : 'Показать' }} карту</span> <img src="@/assets/images/map-mobile.svg" alt=""></a>
          <div id="mapLSK" class="map-content d-none d-sm-block" :class="{'d-block': isOpenMap === 'lsk'}"></div>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import ymaps from 'ymaps'

export default {
  data () {
    return {
      map: null,
      mapLSK: null,
      isOpenMap: ''
    }
  },
  mounted () {
    ymaps
      .load('https://api-maps.yandex.ru/2.1/?lang=ru_RU')
      .then(maps => {
        this.map = maps
        this.map = new maps.Map('mapVRN', {
          center: [51.701295, 39.181539],
          zoom: 16,
          controls: ['geolocationControl']
        })
        this.map.controls.add(new maps.control.ZoomControl(
          {
            options: {
              position: {
                right: 10,
                top: 160
              },
              size: 'small'
            }
          }
        ))
        if (this.map !== null) {
          let markerImg = require('@/assets/images/mark.svg')
          let markVRN = new maps.Placemark([51.701295, 39.181539], {}, {
            iconLayout: 'default#image',
            iconImageHref: markerImg,
            iconImageSize: [30, 42],
            iconImageOffset: [-15, -38]
          })
          this.map.geoObjects.add(markVRN)
        }

        this.mapLSK = new maps.Map('mapLSK', {
          center: [50.984474, 39.520887],
          zoom: 16,
          controls: ['geolocationControl']
        })
        if (this.mapLSK !== null) {
          let markerImg = require('@/assets/images/mark.svg')
          let markVRN = new maps.Placemark([50.984474, 39.520887], {}, {
            iconLayout: 'default#image',
            iconImageHref: markerImg,
            iconImageSize: [30, 42],
            iconImageOffset: [-15, -38]
          })
          this.mapLSK.geoObjects.add(markVRN)
        }
        this.mapLSK.controls.add(new maps.control.ZoomControl(
          {
            options: {
              position: {
                right: 10,
                top: 160
              },
              size: 'small'
            }
          }
        ))
      })
      .catch(error => console.log('Failed to load Yandex Maps', error))
  },
  methods: {
    openMap (city) {
      if (this.isOpenMap === city) {
        this.isOpenMap = ''
      } else {
        this.isOpenMap = city
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/main';

#mapVRN,
#mapLSK {
  width: 100%;
  height: 450px;
}

.map {
  padding: 100px 0;
  .show-map {
    display: none;
    text-align: center;
    margin-bottom: 40px;
    span {
      border-bottom: 1px dashed $main-blue;
    }
  }
  &s-tabs {
    .nav-item {
      margin: 0;
      flex-basis: 50%;
      margin-bottom: -3px;
      .nav-link {
        position: relative;
        padding: 0;
        border: 3px solid transparent;
        border-bottom-color: #E9E9E9;
        padding: 20px 50px 20px;
        color: $main-blue;
        &.active {
          color: $sub-headings;
          background-color: transparent;
          border: 3px solid #E9E9E9;
          border-bottom-color: transparent;
          &:after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 50px;
            right: 50px;
            width: calc(100% - 100px);
            height: 1px;
            background-color: #E9E9E9;
          }
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  &-content {
    margin-top: 30px;
    border-top: 3px solid #E9E9E9;
  }
  .tab-content {
    border: 3px solid #E9E9E9;
    border-top: none;
  }
  &__pane {
    text-align: left;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 50px 0;
  }
  &__item {
    display: flex;
    align-items: center;
    img {
      margin-right: 15px;
    }
    a,
    p {
      color: $text;
      font-weight: 600;
      margin-bottom: 0;
      br {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1319px) {
  .map {
    &__info {
      p {
        br {
          display: block;
        }
      }
    }
    &s-tabs {
      .nav-item {
        .nav-link {
          padding-right: 30px;
          padding-left: 30px;
          &.active {
            &:after {
              left: 30px;
              width: calc(100% - 60px);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .map {
    .container {
      max-width: 100%;
      padding: 0;
    }
    &-content {
      padding: 0 15px;
    }
  }
}

@media (max-width: 991px) {
  .map {
    &__info {
      align-items: flex-start;
    }
    &__item {
      display: block;
      img {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .map {
    &__info {
      display: block;
    }
    &__item {
      display: flex;
      margin-bottom: 35px;
      img {
        margin-bottom: 0;
      }
      p {
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .map {
    padding-top: 30px;
    &__info {
      display: block;
      padding-right: 30px;
      padding-left: 30px;
    }
    .show-map {
      display: block;
    }
    &-content {
      border-top: none;
    }
    &__item {
      display: flex;
      margin-bottom: 35px;
      img {
        margin-bottom: 0;
      }
      p {
        br {
          display: block;
        }
      }
    }
  }
}
</style>