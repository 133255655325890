<template>
  <div class="detail-categories" v-if="categoryDetail !== null && categoryDetail !== undefined">
    <div class="bg-mobile" :style="bg"></div>
    <b-container>
      <b-row>
        <b-col cols="12" md="6">
          <div class="detail-categories__info">
            <div class="detail-categories__wrapper">
              <h3>{{ categoryDetail.name }}</h3>
              <p>{{ categoryDetail.description }}</p>
              <button @click="scroll()" class="btn btn-main-outline">Посмотреть</button>
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="d-none d-md-block">
          <div class="detail-categories__img" :style="{
              'background-image':
                'url(' + categoryDetail.image + ')',
            }">
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div ref="element"></div>
  </div>
</template>

<script>
export default {
  props: ['categorySlug'],
  data () {
    return {
      categoryDetail: null,
      bg: {}
    }
  },
  methods: {
    getDetailCategory () {
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/categories/all/?slug=' + this.categorySlug).then(resp => {
        this.categoryDetail = resp.data
        this.categoryDetail = this.categoryDetail.find(item => {
          return item.slug === this.categorySlug
        })
        this.bgMobile()
      }).catch(err => {
        if (err.response.status === 404) {
          this.$router.push({
            name: 'error'
          })
        }
      })
    },
    bgMobile () {
      if (window.innerWidth < 767) {
        this.bg = {
          backgroundImage: 'url(' + this.categoryDetail.src + ')'
        }
      } else {
        this.bg = {
          backgroundImage: ''
        }
      }
    },
    scroll () {
      this.$refs.element.scrollIntoView({behavior: "smooth"})
    }
  },
  mounted () {
    this.getDetailCategory()
    window.addEventListener('resize', this.bgMobile)
  },
  watch: {
    categorySlug () {
      this.getDetailCategory()
    }
  }
}
</script>

<style lang="scss">
.detail-categories {
  position: relative;
  background-color: #fff;
  padding-bottom: 100px;
  &__info {
    padding-right: 50px;
    h3 {
      font-size: 36px;
      margin-bottom: 20px;
    }
    .btn-main-outline {
      margin-top: 40px;
    }
  }
  .bg-mobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: .1;
  }
  &__img {
    height: 385px;
    background-position: 50% 50%;
  }
}

@media (max-width: 767px) {
  .detail-categories {
    padding-top: 50px;
    text-align: center;
    .bg-mobile {
      display: block;
    }
    &__info {
      padding-right: 0;
    }
    &__img {
      display: none;
    }
  }
}
</style>