<template>
  <div class="last-category-works">
    <b-container>
      <h2 class="section-heading">Наши последние работы</h2>
      <template v-if="otherWorks.length > 0">
        <b-row>
          <b-col cols="12" sm="6" lg="4" v-for="(work, index) in otherWorks" :key="index">
            <div class="last-category-works__block">
              <CardVue :product="work"></CardVue>
            </div>
          </b-col>
        </b-row>
        <div class="text-center">
          <button v-if="nextLink !== null" @click="showMore()" class="btn btn-main-outline">Показать еще</button>
        </div>
      </template>
      <p class="text-center" v-else>Работы в данной категории отсутствуют, но скоро появятся :)</p>
    </b-container>
  </div>
</template>

<script>
import CardVue from '../CardVue'

export default {
  props: ['categorySlug'],
  components: {
    CardVue
  },
  data () {
    return {
      otherWorks: [],
      nextLink: null,
    }
  },
  methods: {
    getOtherWorks (pageLink) {
      if (this.nextLink === null) {
        this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products/?category__slug=' + this.$route.params['categorySlug'] + '&page_size=6').then(resp => {
          this.nextLink = resp.data.next
          this.otherWorks = resp.data.results
        })
      } else {
        this.axios.get(pageLink).then(resp => {
          this.nextLink = resp.data.next
          resp.data.results.forEach(item => {
            this.otherWorks.push(item)
          })
        })
      }
    },
    showMore () {
      if (this.nextLink !== null) {
        this.getOtherWorks(this.nextLink)
      } else return
    }
  },
  mounted () {
    this.getOtherWorks()
  },
  watch: {
    categorySlug () {
      this.getOtherWorks()
    }
  }
}
</script>

<style lang="scss">
.last-category-works {
  padding: 100px 0 50px;
}
</style>