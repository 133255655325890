<template>
  <div class="features">
    <b-container>
      <h2 class="section-heading">Выбирая нас, вы выбираете:</h2>
      <b-row>
        <b-col cols="12" sm="6">
          <div class="features__block">
            <img src="@/assets/images/f-1.svg" alt="" />
            <p>Честные цены</p>
            <span
              >Мы закупаем материалы напрямую у производителей, что позволяет
              нам сохранять адекватные цены при высоком качестве.</span
            >
          </div>
        </b-col>
        <b-col cols="12" sm="6">
          <div class="features__block">
            <img src="@/assets/images/f-2.svg" alt="" />
            <p>Сроки без задержек</p>
            <span
              >Мы сами производим мебель, которую продаем и готовы гарантировать
              своевременное изготовление изделий.</span
            >
          </div>
        </b-col>
        <b-col cols="12" sm="6">
          <div class="features__block">
            <img src="@/assets/images/f-3.svg" alt="" />
            <p>Гарантию качества</p>
            <span
              >Мы используем импортное оборудование, а все наши специалисты
              имеют стаж работы более 8 лет.</span
            >
          </div>
        </b-col>
        <b-col cols="12" sm="6">
          <div class="features__block">
            <img src="@/assets/images/f-4.svg" alt="" />
            <p>Сервис и комфорт</p>
            <span
              >Мы уже 16 лет на рынке мебели и дорожим своей репутацией. Всегда
              принимаем сторону клиента при возникновении спорных
              ситуаций.</span
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.features {
  padding-bottom: 100px;
  &__block {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 80px;
      height: 2px;
      background-color: #e9e9e9;
    }
    img {
      margin-bottom: 20px;
    }
    p {
      font-family: $font-family-bold;
      color: $sub-headings;
      font-size: 22px;
    }
  }
}

@media (min-width: 1320px) {
  .features {
    .col-sm-6 {
      max-width: 25%;
      flex-basis: 25%;
    }
  }
}

@media screen and (max-width: 1319px) {
  .features {
    &__block {
      margin-bottom: 50px;
    }
    .col {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: 575px) {
  .features {
    &__block {
      text-align: center;
      &:after {
        display: none;
      }
    }
  }
}
</style>