<template>
  <div class="block step">
    <b-container>
      <h2 class="section-heading">
        Всего несколько шагов до мебели вашей мечты
      </h2>
      <div class="step__block-wrapper">
        <div class="step__block">
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-1.jpg') + ')',
            }"
          ></div>
          <div class="step__info">
            <p>Вы оставляете заявку</p>
            <span
              >Мы выслушаем вас и предложим лучшие варианты. Бесплатно
              проконсультироваться можно позвонив по телефону или заказав
              звонок.</span
            >
            <button @click="openModal()" class="btn btn-main-outline">Заказать звонок</button>
          </div>
        </div>
        <div class="step__block">
          <div class="step__info">
            <p>Выезд замерщика</p>
            <span
              >Вам грамотно померяют стены, углы, ниши, учтут расположение труб
              и розеток – новая мебель впишется с точностью до миллиметра.</span
            >
          </div>
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-2.jpg') + ')',
            }"
          ></div>
        </div>
        <div class="step__block">
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-3.jpg') + ')',
            }"
          ></div>
          <div class="step__info">
            <p>Составление дизайн-проекта</p>
            <span
              >Наши дизайнеры-консультанты, опираясь на данные замеров и все
              ваши пожелания, создадут 3D-проект новой мебели. Останется выбрать
              из нескольких предложенных вариантов.</span
            >
          </div>
        </div>
        <div class="step__block">
          <div class="step__info">
            <p>Выбор материалов</p>
            <span
              >Вам помогут подобрать фасады, столешницу, фартук, фурнитуру и
              другие материалы, порекомендуют надежных поставщиков бытовой
              техники. У нас только проверенные поставщики и самые надежные
              производители.</span
            >
          </div>
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-4.jpg') + ')',
            }"
          ></div>
        </div>
        <div class="step__block">
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-5.jpg') + ')',
            }"
          ></div>
          <div class="step__info">
            <p>Договор и предоплата</p>
            <span
              >После согласования всех деталей утверждается проект и
              подписывается договор. Теперь можно внести предоплату любым
              удобным вам способом. У нас также предусмотрена рассрочка, условия
              которой оговариваются индивидуально.</span
            >
          </div>
        </div>
        <div class="step__block">
          <div class="step__info">
            <p>Изготовление</p>
            <span
              >Далее заказ отправляется на производство – в наши
              производственные помещения с импортным оборудованием и опытными
              сотрудниками со стажем более 8 лет.</span
            >
          </div>
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-6.jpg') + ')',
            }"
          ></div>
        </div>
        <div class="step__block">
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-7.jpg') + ')',
            }"
          ></div>
          <div class="step__info">
            <p>Доставка и установка</p>
            <span
              >Как только мебель будет готова, мы вам сообщим и договоримся на
              удобное время для установки. Вам останется внести оставшуюся сумму
              и дождаться сборщиков. Они привезут и соберут изделие за один
              день.</span
            >
          </div>
        </div>
        <div class="step__block">
          <div class="step__info">
            <p>Гарантия и сервис</p>
            <span
              >Мебель собрана и вы в восторге! Мы дорожим своей репутацией,
              поэтому если наступает так называемый гарантийный случай, мы в
              любое время приедем к вам – переживать не за что.</span
            >
          </div>
          <div
            class="step__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/step-8.jpg') + ')',
            }"
          ></div>
        </div>
      </div>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "../PopupVue";

export default {
  components: {
    PopupVue,
  },
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    openModal() {
      this.isPopup = true;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.step {
  &__block {
    display: flex;
    height: 200px;
    margin-bottom: 45px;
    &:nth-child(2n) {
      .step__img {
        margin-left: 30px;
      }
    }
    &:nth-child(2n + 1) {
      .step__img {
        margin-right: 30px;
      }
    }
    &:nth-child(1) {
      .step__info {
        &:before {
          content: "1";
        }
      }
    }
    &:nth-child(2) {
      .step__info {
        &:before {
          content: "2";
        }
      }
    }
    &:nth-child(3) {
      .step__info {
        &:before {
          content: "3";
        }
      }
    }
    &:nth-child(4) {
      .step__info {
        &:before {
          content: "4";
        }
      }
    }
    &:nth-child(5) {
      .step__info {
        &:before {
          content: "5";
        }
      }
    }
    &:nth-child(6) {
      .step__info {
        &:before {
          content: "6";
        }
      }
    }
    &:nth-child(7) {
      .step__info {
        &:before {
          content: "7";
        }
      }
    }
    &:nth-child(8) {
      .step__info {
        &:before {
          content: "8";
        }
      }
    }
  }
  &__img {
    flex: none;
    width: 495px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 2px;
  }
  &__info {
    position: relative;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: $sub-headings;
    &:before {
      position: absolute;
      top: -73px;
      left: -5px;
      font-size: 180px;
      font-family: $font-family-bold;
      color: #e5f0f6;
      z-index: -1;
    }
    p {
      font-family: $font-family-bold;
      font-size: 22px;
    }
    span {
      display: block;
    }
    .btn {
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 1319px) {
  .step {
    &__img {
      width: 470px;
    }
  }
}

@media screen and (max-width: 991px) {
  .step {
    &__img {
      width: 240px;
    }
    &__info {
      p {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .step {
    &__block {
      // display: block;
      flex-wrap: wrap;
      flex-basis: 50%;
      height: auto;
      margin-bottom: 25px;
      &-wrapper {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__img {
      order: 1;
      height: 95px;
      margin: 0!important;
    }
    &__info {
      order: 2;
      .btn-main-outline {
        display: none;
      }
      span {
        display: none;
      }
      p {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 0;
      }
      &:before {
        top: -20px;
        font-size: 60px;
      }
    }
  }
}

@media (max-width: 575px) {
  .step {
    &__block {
      justify-content: center;
    }
    &__img {
      display: none;
    }
    &__info {
      text-align: center;
      justify-content: flex-start;
      p {
        margin-top: 50px;
      }
      &:before {
        left: 50%;
        transform: translateX(-50%);
        color: #BDE1F5
      }
    }
  }
}
</style>