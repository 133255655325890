<template>
  <div
    class="special"
    :style="{
      'background-image': 'url(' + require('@/assets/images/need-bg.png') + ')',
    }"
  >
    <b-container>
      <b-row class="align-items-center">
        <b-col cols="5" class="d-lg-block d-none">
          <div
            class="special__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/need-img.jpg') + ')',
            }"
          ></div>
        </b-col>
        <b-col>
          <div class="special__info">
            <h3>Нужно что-то особенное?</h3>
            <p>
              Мы бесплатно создаём для всех клиентов индивидуальный
              дизайн-проект, ведь каждый дом уникален. Оставьте заявку и наши
              специалисты подберут решение для вашего интерьера.
            </p>
            <div class="special__contacts">
              <button @click="openModal()" class="btn btn-main">Заявка онлайн</button>
              <div class="special__phone">
                <p>Или позоните по телефону:</p>
                <div :class="{open: openPhoneList}" class="phone-dropdown">
                  <p @click="openPhoneList = !openPhoneList" class="current-phone">
                    <span>+7 (900) 304-51-91</span>
                    <simple-svg
                      :src="require('@/assets/images/dropdown-item.svg')"
                      width="12px"
                    />
                  </p>
                  <div class="phone-dropdown__list">
                    <div class="phone-dropdown__item">
                      <span>Воронеж</span>
                      <a class="link-phone" href="tel:+79003045191">+7 (900) 304-51-91</a>
                    </div>
                    <div class="phone-dropdown__item">
                      <span>Лиски</span>
                      <a class="link-phone" href="tel:+79529517091">+7 (952) 951-70-91</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на обратный звонок'"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "./PopupVue"

export default {
  components: {
    PopupVue,
  },
  data() {
    return {
      isPopup: false,
      openPhoneList: false
    }
  },
  methods: {
    openModal () {
      this.isPopup = true
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.special {
  background-size: cover;
  margin-bottom: 100px;
  .phone-dropdown {
    a {
      display: block;
      color: $main-blue;
    }
  }
  &__info {
    h3 {
      font-size: 32px;
      margin-bottom: 25px;
    }
  }
  &__img {
    height: 340px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__contacts {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  &__phone {
    margin-left: 45px;
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
    a {
      font-family: $font-family-bold;
      font-size: 22px;
      color: $sub-headings;
    }
  }
}

@media screen and (max-width: 1319px) {
  .special {
    &__img {
      margin-left: -30px;
    }
  }
}

@media (max-width: 991px) {
  .special {
    padding: 65px 0;
  }
}

@media (max-width: 575px) {
  .special {
    text-align: center;
    &__contacts {
      display: block;
      .btn-main {
        max-width: 290px;
        width: 100%;
      }
    }
    &__phone {
      margin-top: 25px;
      margin-left: 0;
    }
  }
}
</style>