<template>
  <kinesis-container class="no-perspective">
    <div class="main-screen">
      <b-container>
        <b-row>
          <b-col lg="5" md="6">
            <div class="main-info">
              <div class="main-heading">
                <h1>Изготовление <br>мебели на заказ</h1>
                <p>Мебель по индивидуальному проекту на любой вкус, цвет и бюджет</p>
              </div>
              <div class="main-features">
                <div class="item item-1">
                  <p>Бесплатный <br />дизайн-проект</p>
                  <div class="img-wrap">
                    <img src="@/assets/images/mf-1.svg" alt="" />
                  </div>
                </div>
                <div class="item item-1">
                  <p>Материалы ведущих <br/>производителей</p>
                  <div class="img-wrap">
                    <img src="@/assets/images/mf-2.svg" alt="" />
                  </div>
                </div>
                <div class="item item-1">
                  <p>Гарантия <br />1 год</p>
                  <div class="img-wrap">
                    <img src="@/assets/images/mf-3.svg" alt="" />
                  </div>
                </div>
              </div>
              <button @click="openModal()" class="btn btn-main">Получить расчет</button>
            </div>
          </b-col>
          <b-col lg="5" md="6">
            <div class="main-img">
              <kinesis-element :strength="10">
                <img src="@/assets/images/22.jpg" alt="" />
              </kinesis-element>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <transition name="fade">
        <PopupVue
          v-if="isPopup"
          :buttonText="'Получить расчет'"
          @closePopup="isPopup = false"
          :titlePopup="'Заявка на расчёт стоимости'"
        >
          <template #title>
            <h2>Заявка на расчёт стоимости</h2>
          </template>
        </PopupVue>
      </transition>
    </div>
  </kinesis-container>
</template>

<script>
import PopupVue from '../PopupVue'

export default {
  components: {
    PopupVue
  },
  data () {
    return {
      isPopup: false
    }
  },
  methods: {
    openModal () {
      this.isPopup = true
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/main';

.main-screen {
  background-color: #fff;
  padding-bottom: 100px;
}

.no-perspective {
  perspective: none!important;
}

.main-features {
  position: relative;
  margin-right: -60px;
  z-index: 1;
  .img-wrap {
    border-radius: 2px;
    background-color: $main-blue;
    padding: 12px;
    margin-left: 15px;
  }
  .item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    color: $text;
    p {
      margin-bottom: 0;
    }
  }
}

.main-info {
  text-align: right;
  .btn-main {
    display: block;
    margin-right: auto;
  }
}

.main-img {
  position: relative;
  overflow: hidden;
  margin-top: -115px;
  background-position: 50% 50%;
  height: 580px;
  img {
    margin-top: -10px;
    margin-left: -30px;
  }
}

.main-heading {
  h1 {
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 30px;
    color: $sub-headings;
    font-weight: 600;
  }
}

@media screen and (max-width: 1319px) {
  .main-img {
    margin-right: -30px;
  }
}

@media (max-width: 1319px) and (min-width: 992px) {
  .main-screen {
    .col-lg-5 {
      max-width: 50%;
      flex-basis: 50%;
    }
  }
}

@media (max-width: 991px) {
  .main-heading {
    h1 {
      font-size: 38px;
    }
  }
}

@media (max-width: 767px) {
  .main-img {
    display: none;
  }
  .main-screen {
    position: relative;
    padding-top: 50px;
    padding-bottom: 75px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(~@/assets/images/main-img.jpg);
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: .1;
    }
  }
  .main-info {
    text-align: center;
    .btn-main {
      margin: 0 auto;
    }
  }
  .main-features {
    margin-right: 0;
    display: flex;
    margin-bottom: 40px;
    .item {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
    }
    .img-wrap {
      order: 1;
      flex-basis: 100%;
      flex: none;
      margin-left: 0;
      margin-bottom: 30px;
    }
    p {
      order: 2;
    }
  }
}

@media (max-width: 565px) {
  .main-features {
    display: block;
    .item {
      justify-content: flex-start;
      margin-bottom: 20px;
      text-align: left;
    }
    .img-wrap {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}
</style>
