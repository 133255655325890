<template>
  <div class="error-page">
    <b-container>
      <div class="error-title text-center">
        <h2>Страница не найдена</h2>
        <p>Упс, такой страницы не существует. Нажмите <br>на логотип, это исправит положение ;)</p>
      </div>
      <div class="error-elements status-404">
        <div class="elem first-elem">
          <div class="error-item">
            <img src="@/assets/images/red.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
        </div>
        <div class="elem second-elem">
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/red.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/red.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
        </div>
        <div class="elem third-elem">
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/white.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/brown.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/red.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/blue.png" alt="">
          </div>
          <div class="error-item">
            <img src="@/assets/images/beige.png" alt="">
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

@keyframes transformPage {
  from {
    top: -100px;
    left: 400px;
  }
  to {
    top: 60px;
    left: 0;
  }
}

.error-page {
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 110px;
  p {
    color: $sub-headings
  }
}

.error-title {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 100px;
    width: 250px;
    height: 90px;
    background: url(~@/assets/images/arrow-error.svg);
  }
}

.error-elements {
  height: 350px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  background: url(~@/assets/images/shadow-error.svg);
  background-repeat: no-repeat;
  background-position: 50% 95%;
}

.elem {
  position: relative;
}

.error-item {
  position: absolute;
}

.status-404 {
  .first-elem {
    width: 180px;
    height: 300px;
    .error-item:nth-child(2) {
      animation: transformPage 1s ease-in-out;
    }
    .error-item:nth-child(2) {
      top: 60px;
    }
    .error-item:nth-child(3) {
      top: 120px;
    }
    .error-item:nth-child(4) {
      top: 120px;
      left: 60px;
    }
    .error-item:nth-child(5),
    .error-item:nth-child(6),
    .error-item:nth-child(7),
    .error-item:nth-child(8),
    .error-item:nth-child(9) {
      left: 120px;
    }
    .error-item:nth-child(5) {
      top: 0;
    }
    .error-item:nth-child(6) {
      top: 60px;
    }
    .error-item:nth-child(7) {
      top: 120px;
    }
    .error-item:nth-child(8) {
      top: 180px;
    }
    .error-item:nth-child(9) {
      top: 240px;
    }
  }

  .second-elem {
    width: 180px;
    margin-left: 40px;
    .error-item:nth-child(2) {
      top: 60px;
    }
    .error-item:nth-child(3) {
      top: 120px;
    }
    .error-item:nth-child(4) {
      top: 180px;
    }
    .error-item:nth-child(5) {
      top: 240px;
    }
    .error-item:nth-child(6) {
      top: 240px;
      left: 60px;
    }
    .error-item:nth-child(7),
    .error-item:nth-child(8),
    .error-item:nth-child(9),
    .error-item:nth-child(10),
    .error-item:nth-child(11) {
      left: 120px;
    }

    .error-item:nth-child(7) {
      top: 240px;
    }
    .error-item:nth-child(8) {
      top: 180px;
    }
    .error-item:nth-child(9) {
      top: 120px;
      left: 120px;
    }
    .error-item:nth-child(10) {
      top: 60px;
      left: 120px;
    }
    .error-item:nth-child(11) {
      top: 0;
      left: 120px;
    }
    .error-item:nth-child(12) {
      top: 0;
      left: 60px;
    }
  }

  .third-elem {
    width: 180px;
    height: 300px;
    margin-left: 40px;
    .error-item:nth-child(2) {
      top: 60px;
    }
    .error-item:nth-child(3) {
      top: 120px;
    }
    .error-item:nth-child(4) {
      top: 120px;
      left: 60px;
    }
    .error-item:nth-child(5),
    .error-item:nth-child(6),
    .error-item:nth-child(7),
    .error-item:nth-child(8),
    .error-item:nth-child(9) {
      left: 120px;
    }
    .error-item:nth-child(5) {
      top: 0;
    }
    .error-item:nth-child(6) {
      top: 60px;
    }
    .error-item:nth-child(7) {
      top: 120px;
    }
    .error-item:nth-child(8) {
      top: 180px;
    }
    .error-item:nth-child(9) {
      top: 240px;
    }
  }
}

@media screen and (max-width: 1319px) {
  .error-title {
    &:after {
      top: -35px;
      left: 25px;
    }
  }
}

@media (max-width: 991px) {
  .error-title {
    &:after {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .error-elements {
    display: none;
  }
  .error-page {
    padding-bottom: 50px;
  }
}

</style>