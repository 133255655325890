<template>
  <div class="popup">
    <div class="popup__dialog">
      <button @click="closePopup()" class="btn popup__close">
        <img src="@/assets/images/close.svg" alt="">
      </button>
      <div class="popup__content">
        <slot name="title"></slot>
        <div class="popup__form">
          <form @submit.prevent="onSubmit">
            <div class="input-wrap" :class="{'error-field': $v.name.$error}">
              <input class="form-control" v-model.trim="$v.name.$model" type="text" placeholder="Имя">
            </div>
            <div class="input-wrap" :class="{'error-field': $v.phone.$error}">
              <input class="form-control input-phone" v-model.trim="$v.phone.$model" type="tel" placeholder="Номер телефона">
            </div>
            <button class="btn btn-main" type="submit">{{ buttonText }}</button>
            <p>Нажимая на кнопку "{{ buttonText }}", вы <br>соглашаетесь с <a href="https://xn----itbibqijjfcod8n.xn--p1ai/static/usloviy.pdf" target="_blank">Политикой конфиденциальности</a></p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Inputmask from 'inputmask'
import { required } from 'vuelidate/lib/validators'

export default {
  props: ['buttonText', 'titlePopup'],
  data () {
    return {
      isPopup: false,
      name: '',
      phone: ''
    }
  },
  validations: {
    name: {
      required
    },
    phone: {
      required
    }
  },
  methods: {
    onSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        console.log('Ошибка')
      } else {
        this.axios.post(process.env.VUE_APP_HOST + '/api/v1/mail/', {
          msg_subject: this.titlePopup,
          msg_content: this.name + ' ' + this.phone
          // msg_content: this.name + ' ' + this.phone
        }).then(response => {
          console.log(response)
          this.closePopup()
          this.$store.dispatch('SET_VALUE', {key: 'isNotification', value: true})
          setTimeout(() => {
            this.$store.dispatch('SET_VALUE', {key: 'isNotification', value: false})
          }, 2000);
        })
      }
    },
    closePopup () {
      this.$emit('closePopup')
    },
    recaptcha () {
      // console.log('recaptcha clicked')
      this.$recaptchaLoaded().then(() => {
        // console.log('recaptcha loaded')
        this.$recaptcha('login').then((token) => {
          this.axios.post('https://www.google.com/recaptcha/api/siteverify', {
            secret: '6LcDs6oaAAAAACQtyjP8hfATYhv2B6E-g-c-JCas',
            response: token
          }).then(resp => {
            console.log(resp)
          })
        })
      })
    }
  },
  mounted () {
    var im = new Inputmask('+7 (999) 999-99-99')
    im.mask(document.querySelector('.input-phone'))
    this.missClick = (item) => {
      let q = document.querySelector('.popup')
      if (item.target === q) {
        this.closePopup()
      }
    }
    document.addEventListener('click', this.missClick)
  }, 
  beforeDestroy () {
    document.removeEventListener('click', this.missClick)
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/main';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 30;
  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    svg {
      fill: $main-blue
    }
  }
  &__dialog {
    position: absolute;
    padding: 65px 65px 50px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    h2 {
      font-size: 26px;
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
      color: $grey;
    }
    a {
      color: $grey;
    }
  }
  .input-wrap {
    display: inline-block;
    width: 250px;
  }
  .btn-main {
    margin-bottom: 30px;
  }
  &__form {
    text-align: center;
  }
  .error-field {
    .form-control {
      border-color: #FF5757;
      &::placeholder {
        color: #FF5757
      }
    }
  }
}

@media screen and (max-width: 1319px) {
  .popup {
    .btn-main {
      width: 250px;
    }
  }
}

@media (max-width: 565px) {
  .popup {
    &__dialog {
      width: 100%;
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}
</style>