<template>
  <div class="notification">
    <img src="@/assets/images/check.svg" alt="">
    <span>Отправлено. Спасибо за заявку!</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  padding: 30px 65px;
  background-color: #fff;
  z-index: 9;
  img {
    margin-right: 30px;
  }
}
</style>