<template>
  <div class="main-categories" v-if="allCategoryList !== null && allCategoryList !== undefined">
    <b-container>
      <h2 class="section-heading">Все категории изготавливаемой мебели</h2>
      <div class="main-categories__inner">
        <div @click="openModal(category.name)" class="main-categories__block" v-for="(category, index) in allCategoryList" :key="index">
          <span>{{ category.name }}</span>
          <button class="btn btn-main">Заказать замер</button>
        </div>
      </div>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать замер'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на вызов замерщика' + ' (' + categoryNameModal + ')'"
      >
        <template #title>
          <h2>Заявка на вызов замерщика ({{ categoryNameModal }})</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "../PopupVue"

export default {
  components: {
    PopupVue
  },
  data () {
    return {
      isPopup: false,
      allCategoryList: null,
      categoryNameModal: ''
    }
  },
  computed: {
    isShowPopup () {
      return this.$store.getters.value('isShowPopup')
    }
  },
  methods: {
    getAllCategories () {
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/categories/all').then(cat => {
        this.allCategoryList = cat.data
      })
    },
    openModal (catName) {
      this.isPopup = true
      this.categoryNameModal = catName
      // this.$emit('popupIsShow', this.isPopup)
    }
  },
  mounted () {
    // console.log(this.isShowPopup)
    this.getAllCategories()
  }
}
</script>

<style lang="scss">
.main-categories {
  margin-bottom: 120px;
  &__inner {
    columns: 2
  }
  &__block {
    position: relative;
    cursor: pointer;
    padding: 25px 0 25px 40px;
    background-color: #fff;
    .btn-main {
      position: absolute;
      top: calc(50% - 29px);
      right: -40px;
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: -62px;
        width: 60px;
        height: 100%;
        background: linear-gradient(270deg, #FFFFFF 32.29%, rgba(255, 255, 255, 0) 100%);
      }
    }
    &:nth-child(even) {
      background: #F1F1F1;
      .btn-main {
        &:after {
          background: linear-gradient(270deg, #F1F1F1 32.29%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
    &:hover {
      .btn-main {
        right: 40px;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-categories {
    &__inner {
      columns: auto
    }
    &__block {
      .btn-main {
        display: none;
      }
    }
  }
}
</style>