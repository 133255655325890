<template>
  <div class="categories-page">
    <BreadCrumbsVue></BreadCrumbsVue>
    <DetailCategoriesVue :categorySlug="categorySlug"></DetailCategoriesVue>
    <LastCategoryWorksVue :categorySlug="categorySlug"></LastCategoryWorksVue>
    <FeaturesVue></FeaturesVue>
    <InfoBlockVue></InfoBlockVue>
  </div>
</template>

<script>
import BreadCrumbsVue from '../BreadCrumbsVue'
import DetailCategoriesVue from './DetailCategoriesVue'
import LastCategoryWorksVue from './LastCategoryWorksVue'
import FeaturesVue from '../FeaturesVue'
import InfoBlockVue from '../InfoBlockVue'

export default {
  components: {
    BreadCrumbsVue,
    DetailCategoriesVue,
    LastCategoryWorksVue,
    FeaturesVue,
    InfoBlockVue
  },
  data () {
    return {
      categorySlug: '',
      categoryName: ''
    }
  },
  methods: {
    setBread () {
      if (this.categorySlug === '') {
        this.categorySlug = this.$route.params['categorySlug']
      }
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products/?category__slug=' + this.categorySlug).then(async (resp) => {
        if (resp.data.results.length > 0) {
          this.categoryName = resp.data.results[0].category.name
        } else {
          let cN = await this.axios.get(process.env.VUE_APP_HOST + '/api/v1/categories/all/?slug=' + this.categorySlug)
          if (cN.data.length === 0) {
            this.$router.push({
              name: 'error'
            })
          } else {
            this.categoryName = cN.data[0].name
          }
        }
        this.$store.dispatch('SET_CRUMBS', [
          {
            text: 'Главная',
            to: '/'
          },
          {
            text: this.categoryName,
            active: true
          }
        ])
      })
    }
  },
  mounted () {
    this.setBread()
  },
  watch: {
    $route (toR) {
      this.categorySlug = toR.params['categorySlug']
      this.setBread()
    }
  }
}
</script>

<style>

</style>