<template>
  <div class="info-block">
    <b-container>
      <h2 class="section-heading__mobile">
        Interior Plus — <br />мебель созданная <br />по высоким стандартам
      </h2>
      <b-row>
        <b-col cols="12" md="6" lg="7">
          <div class="info-block__text">
            <h2 class="section-heading">
              Interior Plus — <br />мебель созданная <br />по высоким стандартам
            </h2>
            <p>
              С нами легко и понятно. Мы предоставляем все необходимые планы и
              чертежи, тщательно следим за реализацией. Все ваши идеи и
              пожелания будут непременно учтены. Вы точно останетесь довольны!
            </p>
            <div class="info-block__features">
              <span>Собственные <br />помещения</span>
              <span>Европейское оборудование</span>
              <span>Специалисты со <br />стажем не менее 8 лет</span>
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="8" md="6" lg="5" class="custom-column">
          <div class="info-block__slider">
            <tiny-slider v-bind="tinySliderOptions">
              <div
                class="info-block__photo"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/info-img.jpg') + ')',
                }"
              ></div>
              <div
                class="info-block__photo"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/info-img-2.jpg') + ')',
                }"
              ></div>
              <div
                class="info-block__photo"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/info-img-3.jpg') + ')',
                }"
              ></div>
              <div
                class="info-block__photo"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/info-img-4.jpg') + ')',
                }"
              ></div>
              <div
                class="info-block__photo"
                :style="{
                  'background-image':
                    'url(' + require('@/assets/images/info-img-5.jpg') + ')',
                }"
              ></div>
            </tiny-slider>
          </div>
        </b-col>
        <b-col cols="12" sm="4" md="12">
          <div class="info-block__features features-mobile">
            <span>Собственные <br />помещения</span>
            <span>Европейское <br>оборудование</span>
            <span>Специалисты со <br />стажем не менее 8 лет</span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
   data () {
    return {
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        nav: false,
        gutter: 8,
        items: 1,
        controlsText: ['', '']
      }
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.info-block {
  padding-bottom: 100px;
  .section-heading {
    text-align: left;
    &__mobile {
      display: none;
    }
  }
  .features-mobile {
    display: none;
  }
  &__text {
    padding-right: 15px;
  }
  &__features {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    span {
      position: relative;
      flex-basis: 33%;
      padding-left: 15px;
      line-height: 18px;
      color: $sub-headings;
      font-weight: 600;
      &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        border-radius: 2px;
        background-color: #b79535;
      }
    }
  }
  &__slider {
    position: relative;
  }
  &__img {
    height: 380px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &__photo {
    height: 380px;
  }
  #tns1 > .tns-item {
    padding-right: 0;
  }
  .custom-column {
    padding: 0;
  }
  &__img {
    height: 380px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1319px) {
  .info-block {
    .section-heading {
      display: none;
      &__mobile {
        display: block;
        font-size: 44px;
        margin-bottom: 50px;
        @media (max-width: 991px) {
          font-size: 38px;
        }
        @media (max-width: 767px) {
          font-size: 34px;
        }
        @media (max-width: 567px) {
          font-size: 30px;
        }
        br {
          display: none;
        }
      }
    }
    &__features {
      span {
        font-size: 14px;
      }
    }
    &__img {
      height: 235px;
    }
    &__photo {
      height: 230px;
    }
    .col-lg-7 {
      padding-right: 0;
    }
  }
}

@media (max-width: 991px) {
  .info-block {
    &__features {
      display: none;
    }
    .features-mobile {
      display: flex;
    }
  }
}

@media (max-width: 767px) and (min-width: 567px) {
  .info-block {
    .col-lg-12 {
      order: 1;
    }
    .col-sm-4 {
      order: 2;
    }
    .col-sm-8 {
      order: 3;
    }
    .features-mobile {
      flex-direction: column;
      margin-top: 0;
      height: 100%;
      span {
        flex-basis: auto;
      }
    }
  }
}

@media (max-width: 565px) {
  .info-block {
    .custom-column {
      padding: 0 15px;
    }
    .features-mobile {
      flex-direction: column;
      span {
        margin-bottom: 20px;
        br {
          display: none;
        }
      }
    }
  }
}
</style>