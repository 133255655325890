var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-block"},[_c('b-container',[_c('h2',{staticClass:"section-heading__mobile"},[_vm._v(" Interior Plus — "),_c('br'),_vm._v("мебель созданная "),_c('br'),_vm._v("по высоким стандартам ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"7"}},[_c('div',{staticClass:"info-block__text"},[_c('h2',{staticClass:"section-heading"},[_vm._v(" Interior Plus — "),_c('br'),_vm._v("мебель созданная "),_c('br'),_vm._v("по высоким стандартам ")]),_c('p',[_vm._v(" С нами легко и понятно. Мы предоставляем все необходимые планы и чертежи, тщательно следим за реализацией. Все ваши идеи и пожелания будут непременно учтены. Вы точно останетесь довольны! ")]),_c('div',{staticClass:"info-block__features"},[_c('span',[_vm._v("Собственные "),_c('br'),_vm._v("помещения")]),_c('span',[_vm._v("Европейское оборудование")]),_c('span',[_vm._v("Специалисты со "),_c('br'),_vm._v("стажем не менее 8 лет")])])])]),_c('b-col',{staticClass:"custom-column",attrs:{"cols":"12","sm":"8","md":"6","lg":"5"}},[_c('div',{staticClass:"info-block__slider"},[_c('tiny-slider',_vm._b({},'tiny-slider',_vm.tinySliderOptions,false),[_c('div',{staticClass:"info-block__photo",style:({
                'background-image':
                  'url(' + require('@/assets/images/info-img.jpg') + ')',
              })}),_c('div',{staticClass:"info-block__photo",style:({
                'background-image':
                  'url(' + require('@/assets/images/info-img-2.jpg') + ')',
              })}),_c('div',{staticClass:"info-block__photo",style:({
                'background-image':
                  'url(' + require('@/assets/images/info-img-3.jpg') + ')',
              })}),_c('div',{staticClass:"info-block__photo",style:({
                'background-image':
                  'url(' + require('@/assets/images/info-img-4.jpg') + ')',
              })}),_c('div',{staticClass:"info-block__photo",style:({
                'background-image':
                  'url(' + require('@/assets/images/info-img-5.jpg') + ')',
              })})])],1)]),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('div',{staticClass:"info-block__features features-mobile"},[_c('span',[_vm._v("Собственные "),_c('br'),_vm._v("помещения")]),_c('span',[_vm._v("Европейское "),_c('br'),_vm._v("оборудование")]),_c('span',[_vm._v("Специалисты со "),_c('br'),_vm._v("стажем не менее 8 лет")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }