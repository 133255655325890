<template>
  <div class="top-features">
    <b-container>
      <div class="top-features__row">
        <div class="top-features__item">
          <img src="@/assets/images/branch-left.svg" alt="" />
          <span>Собственное производство без посредников</span>
          <img src="@/assets/images/branch-right.svg" alt="" />
        </div>
        <div class="top-features__item">
          <img src="@/assets/images/branch-left.svg" alt="" />
          <span>16 лет производим <br />мебель мечты</span>
          <img src="@/assets/images/branch-right.svg" alt="" />
        </div>
        <div class="top-features__item">
          <img src="@/assets/images/branch-left.svg" alt="" />
          <span>Материалы и оборудование ведущих производителей</span>
          <img src="@/assets/images/branch-right.svg" alt="" />
        </div>
        <div class="top-features__item">
          <img src="@/assets/images/branch-left.svg" alt="" />
          <span>Возможна покупка мебели <br />в рассрочку</span>
          <img src="@/assets/images/branch-right.svg" alt="" />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.top-features {
  padding: 40px 0;
  background-color: #f1f1f1;
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    // max-width: 25%;
    flex-basis: 24%;
    text-align: center;
    color: $text;
    font-weight: 500;
  }
}

@media screen and (max-width: 1319px) {
  .top-features {
    &__item {
      font-size: 14px;
      // margin: 0 20px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      img {
        flex: none;
        width: 30px;
      }
    }
  }
}

@media (max-width: 991px) {
  .top-features {
    &__row {
      flex-wrap: wrap;
    }
    &__item {
      flex-basis: 50%;
      margin: 15px 0;
    }
  }
}

@media (max-width: 565px) {
  .top-features {
    &__item {
      flex-basis: 100%;
      max-width: 240px;
      margin: 15px auto;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
    }
  }
}
</style>
