var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block step"},[_c('b-container',[_c('h2',{staticClass:"section-heading"},[_vm._v(" Всего несколько шагов до мебели вашей мечты ")]),_c('div',{staticClass:"step__block-wrapper"},[_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-1.jpg') + ')',
          })}),_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Вы оставляете заявку")]),_c('span',[_vm._v("Мы выслушаем вас и предложим лучшие варианты. Бесплатно проконсультироваться можно позвонив по телефону или заказав звонок.")]),_c('button',{staticClass:"btn btn-main-outline",on:{"click":function($event){return _vm.openModal()}}},[_vm._v("Заказать звонок")])])]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Выезд замерщика")]),_c('span',[_vm._v("Вам грамотно померяют стены, углы, ниши, учтут расположение труб и розеток – новая мебель впишется с точностью до миллиметра.")])]),_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-2.jpg') + ')',
          })})]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-3.jpg') + ')',
          })}),_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Составление дизайн-проекта")]),_c('span',[_vm._v("Наши дизайнеры-консультанты, опираясь на данные замеров и все ваши пожелания, создадут 3D-проект новой мебели. Останется выбрать из нескольких предложенных вариантов.")])])]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Выбор материалов")]),_c('span',[_vm._v("Вам помогут подобрать фасады, столешницу, фартук, фурнитуру и другие материалы, порекомендуют надежных поставщиков бытовой техники. У нас только проверенные поставщики и самые надежные производители.")])]),_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-4.jpg') + ')',
          })})]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-5.jpg') + ')',
          })}),_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Договор и предоплата")]),_c('span',[_vm._v("После согласования всех деталей утверждается проект и подписывается договор. Теперь можно внести предоплату любым удобным вам способом. У нас также предусмотрена рассрочка, условия которой оговариваются индивидуально.")])])]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Изготовление")]),_c('span',[_vm._v("Далее заказ отправляется на производство – в наши производственные помещения с импортным оборудованием и опытными сотрудниками со стажем более 8 лет.")])]),_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-6.jpg') + ')',
          })})]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-7.jpg') + ')',
          })}),_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Доставка и установка")]),_c('span',[_vm._v("Как только мебель будет готова, мы вам сообщим и договоримся на удобное время для установки. Вам останется внести оставшуюся сумму и дождаться сборщиков. Они привезут и соберут изделие за один день.")])])]),_c('div',{staticClass:"step__block"},[_c('div',{staticClass:"step__info"},[_c('p',[_vm._v("Гарантия и сервис")]),_c('span',[_vm._v("Мебель собрана и вы в восторге! Мы дорожим своей репутацией, поэтому если наступает так называемый гарантийный случай, мы в любое время приедем к вам – переживать не за что.")])]),_c('div',{staticClass:"step__img",style:({
            'background-image':
              'url(' + require('@/assets/images/step-8.jpg') + ')',
          })})])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isPopup)?_c('PopupVue',{attrs:{"buttonText":'Заказать звонок'},on:{"closePopup":function($event){_vm.isPopup = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',[_vm._v("Заявка на обратный звонок")])]},proxy:true}],null,false,1960155197)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }