<template>
  <div class="subfooter">
    <b-container>
      <p class="text-center">Обращаем ваше внимание на то, что данный Интернет-сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса РФ.</p>
    </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.subfooter {
  background-color: #F1F1F1;
  padding: 10px 0;
  p {
    font-size: 14px;
    margin: 0;
  }
}
</style>