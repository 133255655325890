<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <!-- <button class="modal-default-button" @click="closeModal()">&times;</button> -->
        <!-- Swiper -->
        <div class="swiper-container swiper-container-main">
          <div class="swiper-wrapper">
            <div class="swiper-slide minimum-height" v-for="(img, index) in photoSet" :key="index">
              <div
                class="swiper-slide-img gallery-preview"
                :style="{'background-image': 'url(' + imgHost + '/media/' + img.src + ')'}">
              </div>
            </div>
          </div>
        </div>

        <!-- Swiper thumbnails -->
        <div class="swiper-container gallery-thumbs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide swiper-slide-thumbs"
              v-for="(img, index) in photoSet"
              :key="index"
            >
              <div
                class="thumbs-img"
                :style="{'background-image': 'url(' + imgHost + '/media/' + img.img_compress + ')'}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
// eslint-disable-next-line
import swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Pagination])
export default {
  props: ['photoSet'],
  data () {
    return {
      imgHost: ''
    }
  },
  mounted () {
    this.imgHost = process.env.VUE_APP_HOST
    this.onMounted()
  },
  methods: {
    onMounted () {
      var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 10,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        loop: false,
        preloadImages: false,
        lazy: {
          loadPrevNext: true
        }
      })
      // eslint-disable-next-line
      var swiper = new Swiper('.swiper-container-main', {
        autoHeight: true,
        observer: true,
        observeParents: true,
        observeChildren: true,
        spaceBetween: 0,
        initialSlide: this.indexImage,
        preloadImages: false,
        lazy: {
          loadPrevNext: true
        },
        keyboard: {
          enabled: true
        },
        effect: 'cube',
        coverflowEffect: {
          rotate: 60,
          slideShadows: false
        },
        loop: false,
        thumbs: {
          swiper: galleryThumbs
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.swiper-container {
  width: 100%;
  // max-width: 700px;
  // min-width: 300px;
  height: 100%;
  margin-bottom: 15px;
}

.swiper-container-main .swiper-slide {
  position: relative;
}

.slide-description {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 15px;
  border-radius: 4px;
  font-size: 14px;
  width: 275px;
  background-color: #fff;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.07);
  .date {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 600;
  }
}

.swiper-slide-img {
  height: 60vh;
  border-radius: 2px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* ................................................ thumbs .......................................................... */

/* .gallery-thumbs .swiper-wrapper {
  justify-content: center;
} */

.gallery-thumbs {
  padding: 0;
  margin-top: 0;
  margin-bottom: 40px;
}

.gallery-thumbs .swiper-slide {
  width: 185px;
  cursor: pointer;
  transition: 0.3s;
  border: 1px solid $main-blue;
  border-radius: 2px;
  opacity: 0.5;
}

.gallery-thumbs .swiper-slide-thumb-active {
  cursor: default;
  opacity: 1;
}

.gallery-thumbs .swiper-slide .thumbs-img {
  height: 100px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.thumbs-caption {
  display: block;
  font-size: 14px;
  color: #6d6e71;
}

/* ............................................. responsiveness ....................................................... */
@media all and (max-width: 520px) {
  .swiper-container {
    width: calc(100vw - 20px);
  }
  .swiper-button-next:after {
    font-size: 20px !important;
    transform: translateX(60%);
  }
  .swiper-button-prev:after {
    font-size: 20px !important;
    transform: translateX(-60%);
  }
  #swiper-button-next-modal {
    font-size: 20px !important;
    transform: translateX(20%);
  }
  #swiper-button-prev-modal {
    font-size: 20px !important;
    transform: translateX(-20%);
  }
  .swiper-pagination {
    padding: 4px;
  }
  .swiper-slide-img {
    height: auto;
    min-height: calc((0.5338 * 100vw) + 48.476px);
  }
  .swiper-pagination .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
  }
  .gallery-thumbs .swiper-slide img {
    height: 14vw;
  }
}

.modal-mask {
  width: 100%;
  padding-top: 30px;
}
</style>
