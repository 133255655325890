<template>
  <div class="contacts">
    <b-container>
      <h2 class="section-heading">Наши контакты</h2>
      <div class="contacts__list">
        <div class="contacts__item">
          <p class="city-name">Воронеж</p>
          <div class="contacts__info">
            <div class="contacts__address">
              <img src="@/assets/images/address.svg" alt="" />
              <div class="cw">
                <span>Адрес:</span>
                <p>г. Воронеж, ул. Московский проспект 67, 2 эт.</p>
              </div>
            </div>
            <div class="contacts__time">
              <img src="@/assets/images/time.svg" alt="" />
              <div class="cw">
                <span>Режим работы:</span>
                <p>Пн-пт: 9:00-19:00 | Сб-вс: 9:00-17:00</p>
              </div>
            </div>
            <div class="contacts__phone">
              <img src="@/assets/images/phone.svg" alt="" />
              <span
                >Номер телефона: <br /><a href="tel:+79003045191"
                  >+7 (900) 304-51-91</a
                ></span
              >
              <button @click="openModal()" class="btn btn-main-outline">Перезвоните мне</button>
            </div>
          </div>
        </div>
        <div class="contacts__item">
          <p class="city-name">Лиски</p>
          <div class="contacts__info">
            <div class="contacts__address">
              <img src="@/assets/images/address.svg" alt="" />
              <div class="cw">
                <span>Адрес:</span>
                <p>г. Лиски, ул. Матросова, 2Б</p>
              </div>
            </div>
            <div class="contacts__time">
              <img src="@/assets/images/time.svg" alt="" />
              <div class="cw">
                <span>Режим работы:</span>
                <p>Пн-пт: 8:00-17:00 | Сб-вс: 8:00-16:00</p>
              </div>
            </div>
            <div class="contacts__phone">
              <img src="@/assets/images/phone.svg" alt="" />
              <span
                >Номер телефона: <br /><a href="tel:+79529517091"
                  >+7 (952) 951-70-91</a
                ></span
              >
              <button @click="openModal()" class="btn btn-main-outline">Перезвоните мне</button>
            </div>
          </div>
        </div>
      </div>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на обратный звонок'"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "./PopupVue"

export default {
  components: {
    PopupVue,
  },
  data() {
    return {
      isPopup: false,
    }
  },
  methods: {
    openModal() {
      this.isPopup = true;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.contacts {
  padding-bottom: 100px;
  &__list {
    display: flex;
    justify-content: space-between;
    border: 3px solid #e9e9e9;
  }
  &__item {
    padding: 40px 50px 60px;
    flex-basis: 50%;
    &:first-child {
      border-right: 3px solid #e9e9e9;
    }
  }
  .city-name {
    font-size: 24px;
    padding-bottom: 20px;
    color: $sub-headings;
    font-family: $font-family-bold;
    border-bottom: 1px solid #e9e9e9;
  }
  &__info {
    p {
      font-weight: 600;
      color: $sub-headings;
      margin-bottom: 0;
    }
    img {
      margin-right: 20px;
    }
  }
  &__address,
  &__time,
  &__phone {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }
  &__phone {
    margin-bottom: 0;
    span {
      margin-right: 35px;
    }
    a {
      font-weight: 600;
      color: $sub-headings;
    }
  }
}

@media screen and (max-width: 1319px) {
  .contacts {
    &__list {
      margin-left: -34px;
      width: calc(100% + 68px);
    }
    &__item {
      padding-right: 25px;
      padding-left: 25px;
    }
  }
}

@media (max-width: 991px) {
  .contacts {
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      flex-basis: 100%;
      &:first-child {
        border-right: none;
        border-bottom: 3px solid #e9e9e9;
      }
    }
  }
}

@media (max-width: 575px) {
  .contacts {
    &__phone {
      flex-wrap: wrap;
      .btn-main-outline {
        flex-basis: 100%;
        margin-top: 35px;
      }
    }
    &__list {
      margin-left: 0;
      width: 100%;
    }
  }
}
</style> 