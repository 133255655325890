export default {
  state: {
    isShowPopup: false,
    categories: null,
    isNotification: false,
    breadcrumbs: []
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setCrumbs (state, val) {
      state.breadcrumbs = state.breadcrumbs.splice(0, state.breadcrumbs.length)
      state.breadcrumbs = val
    }
  },
  actions: {
    SET_VALUE ({commit}, keyValue) {
      commit('setValue', keyValue)
    },
    SET_CRUMBS ({commit}, val) {
      commit('setCrumbs', val)
    }
  },
  getters: {
    value: (state, getters, rootState) => key => {
      for (let _state in rootState) {
        if (key in rootState[_state]) {
          return rootState[_state][key]
        }
      }
    },
    breadcrumbs: (state) => {
      return state.breadcrumbs
    }
  }
}
