<template>
  <div class="card" v-if="product !== null && product !== undefined">
    <div class="photo-set">
      <!-- слайдер если есть фотки -->
      <tiny-slider v-bind="tinySliderOptions" v-if="product.gallery.length > 0">
        <div
          class="card__photo"
          v-for="(img, index) in product.gallery" :key="index"
          :style="{'background-image': getLinkImage(img)}"
        ></div>
      </tiny-slider>
      <div class="card__photo" v-else>
        <div class="card__photo" :style="{'background-image': 'url(http://xn----itbibqijjfcod8n.xn--p1ai/static/images/no-photo.jpg)'}"></div>
      </div>
      <span v-if="product.gallery.length > 1" class="counter-img">{{ product.gallery.length }} фото</span>
    </div>
    <router-link
      class="card__link" 
      :to="{name: 'cardDetail', params: {categorySlug: product.category.slug, productId: product.id, categoryName: product.category.name, productName: product.name}}"
    >
      <div class="card__info">
        <div class="card__title">
          {{ product.name }}
        </div>
        <div class="card__price">
          <span>Цена:</span>
          <span class="price-value">{{ product.price.toLocaleString('ru-RU') }} <font>₽</font></span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['product'],
  data () {
    return {
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        nav: false,
        gutter: 8,
        items: 1,
        controlsText: ['', '']
      }
    }
  },
  methods: {
    getLinkImage (img) {
      if (img === undefined || img.src === undefined || img === null || img.src === null) { // если почему-то фото есть, но нет ссылки вернуть no-img
        return 'url(' + process.env.VUE_APP_HOST + '/static/images/no-photo.jpg")'
      }
      return 'url(' + process.env.VUE_APP_HOST + '/media/' + img.src + ')' // если все ок вернуть ссылку
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";
@import '~tiny-slider/src/tiny-slider';

.card {
  border: none;
  border-radius: 0;
  margin-bottom: 30px;
  &__photo {
    height: 290px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &__info {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 30px;
    border: 2px solid $grey;
    span {
      display: block;
    }
  }
  &__title {
    flex-basis: 70%;
  }
  &__price {
    text-align: right;
  }
  span {
    color: $text;
    font-family: $font-family-base;
  }
  .price-value {
    font-family: $font-family-bold;
    color: $sub-headings;
    font-size: 20px;
    font {
      font-family: $font-family-rub;
    }
  }
  &__link {
    font-size: 18px;
    font-family: $font-family-bold;
  }
  .counter-img {
    position: absolute;
    bottom: 0;
    right: 0;
    display: inline-block;
    padding: 4px 8px;
    font-size: 14px;
    text-transform: uppercase;
    background: rgba(236, 236, 236, 0.8);
    border-radius: 0px 2px;
  }
  .photo-set {
    position: relative;
  }
}

@media screen and (max-width: 1319px) {
  .card {
    &__photo {
      height: 220px;
    }
    &__title {
      flex-basis: 50%;
    }
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  .card {
    &__info {
      padding: 15px 20px;
      font-size: 14px;
    }
    &__photo {
      height: 180px;
    }
    &__link {
      font-size: 14px;
    }
    .price-value {
      font-size: 18px;
    }
  }
}
</style>