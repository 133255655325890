<template>
  <div class="detail" v-if="productDetail !== null && productDetail !== undefined && !reinitSlider">
    <b-container>
      <h2 class="card-title mobile-card-title">{{ productDetail.name }}</h2>
      <b-row>
        <b-col cols="12" sm="6">
          <div class="photo-set">
            <template>
              <tiny-slider v-bind="tinySliderOptions">
                <template v-if="productDetail.gallery.length > 0">
                  <div
                    class="detail__img"
                    v-for="(img, index) in productDetail.gallery" :key="index"
                    :style="{'background-image': 'url(' + imgHost + '/media/' + img.img_compress + ')'}"
                  >
                  </div>
                </template>
                <div class="detail__wrapper" v-else>
                  <div class="detail__img" :style="{'background-image': 'url(' + imgHost + '/static/images/no-photo.jpg)'}"></div>
                </div>
              </tiny-slider>
            </template>
            <button
              class="btn loupe"
              @click="showGallery = true"
            >
              <img src="@/assets/images/loupe.svg" alt="">
            </button>
            <span v-if="productDetail.gallery.length > 1" class="counter-img">{{ productDetail.gallery.length }} фото</span>
          </div>
        </b-col>
        <b-col cols="12" sm="6">
          <div class="detail__info">
            <h2 class="card-title">{{ productDetail.name }}</h2>
            <div class="card-info" :class="{open: isFull}" v-html="productDetail.description"></div>
            <a class="more-link" @click="isFull = true" v-if="isFull === false" href="javascript:void(0)">Все описание</a>
            <span class="price">{{ productDetail.price.toLocaleString('ru-RU') }} <font>₽</font></span>
            <div class="btn-group">
              <button @click="openModal()" class="btn btn-main" type="button">Хочу так же</button>
              <button @click="openModal()" class="btn btn-main-outline" type="button">Хочу по-своему</button>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mobile-btn-group">
        <button @click="openModal()" class="btn btn-main" type="button">Хочу так же</button>
        <button @click="openModal()" class="btn btn-main-outline" type="button">Хочу по-своему</button>
      </div>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на обратный звонок'"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
    <transition name="fade">
      <PopupGalleryVue
        v-if="showGallery"
        @closePopup="showGallery = false"
      >
        <template #content>
          <GalleryVue :photoSet="productDetail.gallery"></GalleryVue>
        </template>
      </PopupGalleryVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from '../PopupVue'
import GalleryVue from '../GalleryVue'
import PopupGalleryVue from '../PopupGalleryVue'

export default {
  components: {
    PopupVue,
    GalleryVue,
    PopupGalleryVue
  },
  data () {
    return {
      productDetail: null,
      isFull: false,
      imgHost: '',
      showGallery: false,
      imageArr: [],
      tinySliderOptions: {
        mouseDrag: false,
        touch: true,
        loop: false,
        nav: false,
        gutter: 8,
        items: 1,
        controlsText: ['', '']
      },
      isPopup: false,
      reinitSlider: false
    }
  },
  methods: {
    setTitle (title) {
      this.openModal()
      return title
    },
    openModal() {
      this.isPopup = true
    },
    getDetailProduct () {
      this.isFull = false
      this.imgHost = process.env.VUE_APP_HOST
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products/' + this.$route.params.productId).then(resp => {
        this.productDetail = resp.data
        this.productDetail.gallery.forEach(item => {
          let img = {
            src: this.imgHost + '/media/' + item.src,
            thumbnail: this.imgHost + '/media/' + item.src,
            w: 0,
            h: 0
          }
          this.imageArr.push(img)
        })
        this.$emit('productBread', {
          product: this.productDetail
        })
        this.reinitSlider = true
        setTimeout(() => {
          this.reinitSlider = false
          this.$nextTick(() => {
            let cardInfoHeight = document.querySelector('.card-info')
            if (cardInfoHeight.clientHeight < 160) {
              this.isFull = true
            }
          })
        }, 10)
      }).catch(err => {
        if (err.response.status === 404) {
          this.$router.push({
            name: 'error'
          })
        }
      })
    }
  },
  watch : {
    $route () {
      this.getDetailProduct()
    }
  },
  mounted () {
    this.getDetailProduct()
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";
@import '~tiny-slider/src/tiny-slider';

.detail {
  padding-bottom: 60px;
  background-color: #fff;
  .photo-set {
    position: relative;
    &:hover {
      .loupe {
        transform: scale(1);
      }
      .detail__img {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__img {
    position: relative;
    height: 450px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(1, 20, 31,.2), rgba(1, 20, 31,.2));
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }
  }
  .loupe {
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
    transform: scale(0);
    padding: 0;
    cursor: pointer;
    z-index: 3;
    transition: .3s;
  }
  .counter-img {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 4px 8px;
    font-size: 14px;
    text-transform: uppercase;
    background: rgba(236, 236, 236, 0.5);
    border-radius: 0px 2px;
  }
  &__info {
    .price {
      margin: 20px 0 25px;
      display: block;
      font-size: 28px;
      font-family: 'OpenSans_B';
      color: $sub-headings;
      font {
        font-family: $font-family-rub;
      }
    }
    .btn-main {
      margin-right: 30px;
    }
    .card-info {
      span {
        font-family: $font-family-base!important;
      }
      position: relative;
      max-height: 160px;
      overflow: hidden;
      margin-bottom: 20px;
      &:after {
        position: absolute;
        content: "";
        bottom: -25px;
        left: 0;
        width: 100%;
        height: 40px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 39.01%, #FFFFFF 100%);
      }
      &.open {
        max-height: none;
        &:after {
          display: none;
        }
      }
      p {
        margin-bottom: 10px;
      }
    }
    .more-link {
      display: inline-block;
      border-bottom: 1px dashed $main-blue;
    }
  }
  .mobile-card-title {
    display: none;
    margin-bottom: 30px;
  }
  .mobile-btn-group {
    display: none;
    margin-top: 30px;
    .btn-main {
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 1319px) {
  .detail {
    .mobile-card-title {
      display: block;
    }
    &__img {
      height: 400px;
    }
    &__info {
      .card-title {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .detail {
    &__info {
      .btn-group {
        display: none;
      }
      .price {
        margin-bottom: 0;
      }
    }
    &__img {
      height: 290px;
    }
    .mobile-btn-group {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .detail {
    &__img {
      height: 190px;
    }
    &__info {
      .card-info {
        height: 90px;
      }
    }
  }
}

@media (max-width: 565px) {
  .detail {
    padding-top: 25px;
    &__img {
      max-width: 100%;
    }
    .mobile-btn-group {
      .btn {
        width: 100%;
        max-width: 290px;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .loupe {
      transform: scale(1);
    }
    &__info {
      margin-top: 25px;
    }
  }
}
</style>