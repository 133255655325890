<template>
  <div class="header" :class="{'main-header': isMain}">
    <b-container>
      <b-row>
        <b-col cols="5" class="d-flex align-items-center" :class="{'col-lg-9': !isMain}">
          <div class="header__left d-flex">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/images/logo.svg" alt="" />
              </router-link>
            </div>
            <div class="header__menu" :class="{'is-mobile': isMobile}">
              <ul class="list-unstyled">
                <li class="menu-item dropdown">
                  <a href="javascript:void(0)"
                    >Наши работы
                    <simple-svg
                      :src="require('@/assets/images/dropdown-item.svg')"
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li v-for="(item, index) in categoriesList" :key="index">
                      <router-link :to="'/category/' + item.slug">{{ item.name }}</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item">
                  <router-link to="/contacts">Контакты</router-link>
                </li>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <div class="header__right d-flex text-right">
            <div class="header__right-block">
              <div class="header__contacts">
                <div :class="{open: openPhoneList}" class="phone-dropdown">
                  <p @click="openPhoneList = !openPhoneList" class="current-phone">
                    <span>+7 (900) 304-51-91</span>
                    <simple-svg
                      :src="require('@/assets/images/dropdown-item.svg')"
                      width="12px"
                    />
                  </p>
                  <div class="phone-dropdown__list">
                    <div class="phone-dropdown__item">
                      <span>Воронеж</span>
                      <a class="link-phone" href="tel:+79003045191">+7 (900) 304-51-91</a>
                    </div>
                    <div class="phone-dropdown__item">
                      <span>Лиски</span>
                      <a class="link-phone" href="tel:+79529517091">+7 (952) 951-70-91</a>
                    </div>
                  </div>
                </div>
                <div class="link-modal">
                  <a @click="openModal()" class="link-modal" href="javascript:void(0)"
                    >Перезвоните мне</a
                  >
                  <img src="@/assets/images/phone-header.svg" alt="" />
                </div>
              </div>
              <button class="btn hamburger" :class="{'is-active': isMobile}" @click="openMenu()">
                <span class="hamburger-line"></span>
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на обратный звонок'"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import PopupVue from './PopupVue'

export default {
  mixins: [ clickaway ],
  components: {
    PopupVue
  },
  data () {
    return {
      categoriesList: null,
      isPopup: false,
      openPhoneList: false,
      isMain: null,
      isMobile: false
    }
  },
  watch: {
    '$route' () {
      this.isMobile = false
      this.checkMainPage()
    }
  },
  methods: {
    away () {
      this.isMobile = false
    },
    openMenu () {
      this.isMobile = !this.isMobile
    },
    getCategories () {
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/categories/no-empty').then(category => {
        this.categoriesList = category.data
        // console.log(this.categoriesList)
        this.$store.dispatch('SET_VALUE', {key: 'categories', value: this.categoriesList})
      })
    },
    checkMainPage () {
      if (this.$route.path === '/') {
        this.isMain = true
      }
      else {
        this.isMain = false
      }
    },
    openModal() {
      this.isPopup = true
    }
  },
  created () {
    this.getCategories()
    this.checkMainPage()
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.header {
  position: relative;
  background-color: rgba(255, 255, 255, .8);
  border-bottom: 1px solid #F1F1F1;
  padding: 15px 0;
  &.main-header {
    border-bottom: none;
    .col-5 {
      max-width: 41.666667%;
      flex-basis: 41.666667%;
    }
  }
  &__left {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__right {
    padding-right: 10px;
    &-block {
      margin-left: 15px;
    }
    a {
      display: block;
      font-family: $font-family-bold;
      font-size: 16px;
      margin-top: -5px;
    }
    .link-modal {
      a {
        display: inline-block;
        color: $text;
        font-family: $font-family-base;
        border-bottom: 1px dashed $text;
        margin-right: 5px;
        &:hover {
          color: $main-blue;
          border-color: $main-blue;
        }
      }
    }
  }
  &__menu {
    .list-unstyled {
      margin-bottom: 0;
    }
    .menu-item {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      font-size: 14px;
      transition: 0.3s;
      & > a {
        display: block;
        padding: 8px 20px 11px;
      }
    }
    .dropdown {
      svg {
        fill: $main-blue;
        margin-top: -2px;
        margin-left: 3px;
        transition: 0.3s;
      }
      &:hover {
        background-color: #f8f8f8;
        svg {
          transform: rotate(180deg);
        }
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          top: 40px;
          transition: 0.3s;
        }
      }
    }
    .dropdown-menu {
      background-color: #f8f8f8;
      border-radius: 0;
      top: -100px;
      display: block;
      visibility: hidden;
      opacity: 0;
      padding: 0 20px 30px;
      margin-top: 0;
      width: 225px;
      border: none;
      li {
        border-bottom: 1px solid #e1e3eb;
        &:last-child {
          a {
            padding-bottom: 0;
          }
          border: none;
        }
      }
      a {
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        color: #6c80a5;
        &:hover {
          color: $main-blue;
        }
      }
    }
  }
  .hamburger {
    display: none;
    width: 35px;
    height: 35px;
    margin-left: 5px;
    text-align: center;
    padding: 0;
    &-line {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 3px;
      width: 17px;
      border-radius: 1px;
      background-color: $main-blue;
      transition: .3s;
      &:before,
      &:after {
        position: absolute;
        content: "";
        left: 0;
        width: 20px;
        height: 3px;
        border-radius: 3px;
        background-color: $main-blue;
      }
      &:before {
        top: -5px;
        transition: .2s;
      }
      &:after {
        bottom: -5px;
        transition: .3s;
      }
    }
  }
}

.phone-dropdown {
  position: relative;
  .current-phone {
    position: relative;
    font-size: 20px;
    margin-bottom: 0;;
    color: $main-blue;
    font-family: $font-family-bold;
    cursor: pointer;
    z-index: 10;
    span {
      margin-right: 5px;
      transition: .3s;
    }
    svg {
      fill: $main-blue;
      transition: .3s;
    }
  }
  &__list {
    position: absolute;
    top: 0px;
    right: -10px;
    padding: 10px 20px;
    text-align: left;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    background-color: #F8F8F8;
    transition: .3s;
    z-index: 9;
  }
  &__item {
    padding: 10px 0;
    &:first-child {
      border-bottom: 1px solid #fff;
    }
    span {
      color: $main-blue;
      font-size: 15px;
    }
    a {
      font-size: 20px;
    }
  }
  &.open {
    .current-phone {
      span {
        opacity: 0;
      }
      svg {
        transform: rotate(180deg);
      }
    }
    .phone-dropdown__list {
      opacity: 1;
      visibility: visible;
    }
  }
}

@media screen and (max-width: 1319px) {
  .header {
    z-index: 1;
    &.main-header .col-5 {
      max-width: 50%;
      flex-basis: 50%;
    }
    .col-5 {
      max-width: 72%;
      flex-basis: 72%;
    }
  }
}

@media (max-width: 991px) {
  .header {
    .col-5 {
      max-width: 65%;
      flex-basis: 65%
    }
    &.main-header {
      .col-5 {
        max-width: 60%;
        flex-basis: 60%;
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    .link-modal {
      display: none;
    }
    &__menu {
      .menu-item {
        & > a {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
    }
    .col-5 {
      max-width: 68.333%;
      flex-basis: 68.333%
    }
    &.main-header {
      .col-5 {
        max-width: 68.333%;
        flex-basis: 68.333%;
      }
    }
    &__right {
      padding-right: 0;
      &-block {
        margin-left: 0;
      }
      a {
        font-size: 14px;
      }
    }
    .col {
      padding: 0;
    }
  }
  .main-features {
    z-index: auto;
  }
  .phone-dropdown {
    .current-phone {
      font-size: 16px;
    }
  }
}

@media (max-width: 575px) {
  .header {
    position: relative;
    &__menu {
      position: absolute;
      top: -1000px;
      left: 15px;
      padding-bottom: 15px;
      width: calc(100% - 30px);
      background-color: #F8F8F8;
      z-index: 1031;
      transition: .3s;
      ul {
        padding: 10px 25px 10px 10px;
      }
      .menu-item {
        width: calc(100% - 10px);
        border-bottom: 1px solid #e1e3eb;
        &.dropdown {
          border-bottom: none;
        }
        &:last-child {
          border-bottom: none;
        }
        svg {
          display: none;
        }
      }
      .dropdown-menu {
        position: static;
        visibility: visible;
        width: 100%;
        padding-right: 0;
        padding-bottom: 15px;
        font-size: 14px;
        background-color: transparent;
        opacity: 1;
        li:last-child {
          border-bottom: 1px solid #e1e3eb;
          a {
            padding-bottom: 15px;
          }
        }
      }
      &.is-mobile {
        top: 50px;
      }
    }
    &__right {
      .link-modal {
        display: none;
      }
      &-block {
        display: flex;
        align-items: center;
      }
    }
    .hamburger {
      display: block;
      &.is-active {
        background-color: #F8F8F8;
        .hamburger-line {
          transform: rotate(45deg);
          width: 20px;
          &:before {
            opacity: 0;
          }
          &:after {
            bottom: 0;
            transform: rotate(-90deg);
          }
        }
      }
    }
    &.main-header .col-5,
    .col-5 {
      position: static;
      max-width: 25%;
      flex-basis: 25%
    }
    
  }
  .phone-dropdown {
    .current-phone {
      font-size: 14px;
    }
  }
}
</style>