<template>
  <div class="breadcrumbs">
    <b-container>
      <b-breadcrumb :items="items"></b-breadcrumb>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    items () {
      // return this.$store.getters.value('breadcrumbs')
      return this.$store.getters.breadcrumbs
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 575px) {
  .breadcrumbs {
    display: none;
  }
}
</style>