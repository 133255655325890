<template>
  <div class="portfolio-page-wrapper">
    <BreadCrumbsVue></BreadCrumbsVue>
    <DetailCardVue @productBread='setBread'></DetailCardVue>
    <OtherWorksVue></OtherWorksVue>
    <NeedSpecialVue></NeedSpecialVue>
    <InfoBlockVue></InfoBlockVue>
    <SectionFormVue></SectionFormVue>
    <ContactsVue></ContactsVue>
  </div>
</template>

<script>
import BreadCrumbsVue from '../BreadCrumbsVue'
import DetailCardVue from './DetailCardVue'
import OtherWorksVue from './OtherWorksVue'
import NeedSpecialVue from '../NeedSpecialVue'
import InfoBlockVue from '../InfoBlockVue'
import SectionFormVue from '../SectionFormVue'
import ContactsVue from '../ContactsVue'

export default {
  components: {
    BreadCrumbsVue,
    DetailCardVue,
    OtherWorksVue,
    NeedSpecialVue,
    InfoBlockVue,
    SectionFormVue,
    ContactsVue
  },
  methods: {
    setBread (data) {
      if (data !== undefined) {
        let categoryBread = '/category/' + data.product.category.slug
        this.$store.dispatch('SET_VALUE', {key: 'breadcrumbs', value: [
          {
            text: 'Главная',
            href: '/'
          },
          {
            text: data.product.category.name,
            to: categoryBread
          },
          {
            text: data.product.name,
            active: true
          }
        ]})
      }
    }
  },
  mounted () {
    this.setBread()
  }
}
</script>

<style>

</style>