<template>
  <div class="popup popup-gallery">
    <div class="popup__dialog">
      <button @click="closePopup()" class="btn popup__close">
        <img src="@/assets/images/close.svg" alt="">
      </button>
      <div class="popup__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isPopup: false,
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  },
  mounted () {
    this.missClick = (item) => {
      let q = document.querySelector('.popup')
      if (item.target === q) {
        this.closePopup()
      }
    }
    document.addEventListener('click', this.missClick)
  }, 
  beforeDestroy () {
    document.removeEventListener('click', this.missClick)
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/main';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 30;
  &__close {
    position: absolute;
    top: 20px;
    right: 15px;
    svg {
      fill: $main-blue
    }
  }
  &__dialog {
    position: absolute;
    padding: 65px 65px 50px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #fff;
    h2 {
      font-size: 26px;
      margin-bottom: 25px;
    }
    p {
      font-size: 14px;
      color: $grey;
    }
    a {
      color: $grey;
    }
  }
  &-gallery {
    .popup__content {
      height: 100%;
    }
    .popup__dialog {
      padding: 30px 50px;
      width: 60vw;
      height: 90vh;
    }
  }
  .input-wrap {
    display: inline-block;
    width: 250px;
  }
  .btn-main {
    margin-bottom: 30px;
  }
  &__form {
    text-align: center;
  }
  .error-field {
    .form-control {
      border-color: #FF5757;
      &::placeholder {
        color: #FF5757
      }
    }
  }
}

@media screen and (max-width: 1319px) {
  .popup {
    .btn-main {
      width: 250px;
    }
  }
}

@media (max-width: 565px) {
  .popup {
    &__dialog {
      width: 100%;
      padding-right: 5px;
      padding-left: 5px;
    }
    &-gallery {
      .popup__dialog {
        padding: 30px 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>