<template>
  <div class="form">
    <b-container>
      <b-row class="align-items-center">
        <b-col lg="7">
          <div class="form__info">
            <h3>Новая мебель в рассрочку!</h3>
            <p>
              В Interior Plus вы можете заказать мебель мечты не переживая о
              цене! Мы предалагем простую рассрочку без скрытых платежей и
              переплат.
            </p>
            <p>
              Остались вопросы? Задайте их нашим специалистам. Это бесплатно и
              ни к чему вас не обязывает.
            </p>
            <div class="form__contacts">
              <button @click="openModal()" class="btn btn-main">
                Задать вопрос
              </button>
              <div class="form__phone">
                <p>Или позоните по телефону:</p>
                <!-- <a href="tel:+74974323412">+7 (497) 432-34-12</a> -->
                <div :class="{open: openPhoneList}" class="phone-dropdown">
                  <p @click="openPhoneList = !openPhoneList" class="current-phone">
                    <span>+7 (900) 304-51-91</span>
                    <simple-svg
                      :src="require('@/assets/images/dropdown-item.svg')"
                      width="12px"
                    />
                  </p>
                  <div class="phone-dropdown__list">
                    <div class="phone-dropdown__item">
                      <span>Воронеж</span>
                      <a class="link-phone" href="tel:+79003045191">+7 (900) 304-51-91</a>
                    </div>
                    <div class="phone-dropdown__item">
                      <span>Лиски</span>
                      <a class="link-phone" href="tel:+79529517091">+7 (952) 951-70-91</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div
            class="form__img"
            :style="{
              'background-image':
                'url(' + require('@/assets/images/form-img.jpg') + ')',
            }"
          ></div>
        </b-col>
      </b-row>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Заказать звонок'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на обратный звонок'"
      >
        <template #title>
          <h2>Заявка на обратный звонок</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "./PopupVue"

export default {
  components: {
    PopupVue,
  },
  data() {
    return {
      isPopup: false,
      openPhoneList: false
    }
  },
  methods: {
    openModal() {
      this.isPopup = true;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.form {
  background: $main-blue url(~@/assets/images/form-bg.png) no-repeat;
  background-size: cover;
  color: #fff;
  margin-bottom: 100px;
  .phone-dropdown {
    .current-phone {
      color: #fff;
      svg {
        fill: #fff;
      }
    }
    a {
      display: block;
      color: $main-blue;
    }
  }
  a {
    color: #fff;
  }
  .popup__content {
    .btn-main {
      background-color: $main-blue;
      color: #fff;
      &:hover {
        background-color: #095179;
      }
    }
    a {
      color: $grey;
      &:hover {
        color: #095179;
      }
    }
  }
  .btn-main {
    background-color: #fff;
    color: $main-blue;
  }
  &__info {
    h3 {
      font-size: 32px;
      margin-bottom: 40px;
      color: #fff;
    }
  }
  &__contacts {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }
  &__phone {
    margin-left: 45px;
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
    a {
      font-family: $font-family-bold;
      font-size: 22px;
      color: #fff;
    }
  }
  &__img {
    height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 1319px) {
  .form {
    &__img {
      margin-right: -30px;
    }
  }
}

@media (max-width: 991px) {
  .form {
    &__info {
      padding: 60px 0;
    }
    &__img {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .form {
    &__info {
      text-align: center;
    }
    &__contacts {
      display: block;
    }
    &__phone {
      margin-top: 25px;
      margin-left: 0;
    }
  }
}
</style>