<template>
  <div class="contacts-block">
    <b-container>
      <b-row>
        <b-col class="col-12 col-md-6">
          <div class="contacts-block__info">
            <div class="contacts-block__wrapper">
              <h3>Контактная информация</h3>
              <p>Сейчас Interior Plus работает в двух городах Воронежской области: Лисках и Воронеже. Ознакомиться с подробной информацией можно ниже.</p>
              <button @click="scroll()" class="btn btn-main-outline">Посмотреть</button>
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="d-none d-md-block">
          <div class="contacts-block__map" :style="{
              'background-image':
                'url(' + require('@/assets/images/map-img.jpg') + ')',
            }">
            <!-- <img src="@/assets/images/map-img.jpg" alt=""> -->
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div ref="element"></div>
  </div>
</template>

<script>
export default {
  methods: {
    scroll () {
      this.$refs.element.scrollIntoView({behavior: "smooth"})
    }
  }
}
</script>

<style lang="scss">
.contacts-block {
  background-color: #fff;
  padding-bottom: 95px;
  &__info {
    padding-top: 30px;
    padding-right: 50px;
    h3 {
      font-size: 36px;
      margin-bottom: 25px;
    }
    .btn-main-outline {
      margin-top: 35px;
    }
  }
  &__map {
    width: 100%;
    height: 385px;
    background-position: 50% 50%;
  }
}

@media (max-width: 991px) {
  .contacts-block {
    &__info {
      h3 {
        font-size: 34px;
      }
    }
    &__map {
      width: calc(100% + 30px)
    }
  }
}

@media (max-width: 767px) {
  .contacts-block {
    position: relative;
    &__info {
      text-align: center;
      padding-bottom: 60px;
      padding-right: 0;
      h3 {
        font-size: 30px;
      }
    }
    &:before {
      position: absolute;
      content: "";
      top: -81px;
      left: 0;
      width: 100%;
      height: calc(100% + 81px);
      background-image: url(~@/assets/images/map-img.jpg);
      opacity: .05;
    }
  }
}

@media (max-width: 575px) {
  .contacts-block {
    padding-bottom: 30px;
    &:before {
      top: 0;
      height: 100%;
    }
  }
}
</style>