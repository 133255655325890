<template>
  <div>
    <MainScreenVue></MainScreenVue>
    <TopFeaturesVue></TopFeaturesVue>
    <MainPortfolioVue></MainPortfolioVue>
    <CategoriesBlockVue></CategoriesBlockVue>
    <NeedSpecialVue></NeedSpecialVue>
    <FeaturesVue></FeaturesVue>
    <StepVue></StepVue>
    <SectionFormVue></SectionFormVue>
    <InfoBlockVue></InfoBlockVue>
    <ContactsVue></ContactsVue>
  </div>
</template>

<script>
import MainScreenVue from './MainScreenVue'
import TopFeaturesVue from './TopFeaturesVue'
import MainPortfolioVue from './MainPortfolioVue'
import CategoriesBlockVue from './CategoriesBlockVue'
import NeedSpecialVue from '../NeedSpecialVue'
import FeaturesVue from '../FeaturesVue'
import StepVue from './StepVue'
import SectionFormVue from '../SectionFormVue'
import InfoBlockVue from '../InfoBlockVue'
import ContactsVue from '../ContactsVue'

export default {
  components: {
    MainScreenVue,
    TopFeaturesVue,
    MainPortfolioVue,
    CategoriesBlockVue,
    NeedSpecialVue,
    FeaturesVue,
    StepVue,
    SectionFormVue,
    InfoBlockVue,
    ContactsVue
  }
}
</script>

<style>

</style>