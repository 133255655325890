<template>
  <div class="block portfolio">
    <b-container>
      <h2 class="section-heading">Наши последние работы</h2>
      <div class="text-center">
        <b-dropdown class="portfolio-dropdown" :text="currentTab === 0 ? 'Все категории' : categories[currentTab - 1].name">
          <b-dropdown-item @click="currentTab = 0">
            Все категории
          </b-dropdown-item>
          <b-dropdown-item v-for="(cat, index) in categories" @click="currentTab = (index + 1)" :key="'category_tab_' + index">
            {{ cat.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="d-flex justify-content-center" v-if="loadingCategoryOrProducts">
        <div class="loader">
          <div class="face">
            <div class="circle"></div>
          </div>
          <div class="face">
            <div class="circle"></div>
          </div>
          <button class="btn" @click="getProducts()">
            <img src="@/assets/images/refresh.svg" alt="">
          </button>
        </div>
      </div>
      <b-tabs v-model="currentTab" class="portfolio-tabs" align="center" lazy v-else>
        <b-tab title="Все категории">
          <template v-if="fullListProduct !== null">
            <b-row>
              <b-col cols="12" sm="6" lg="4" v-for="(single, index) in fullListProduct" :key="index">
                <CardVue :product="single"></CardVue>
              </b-col>
            </b-row>
            <div class="text-center">
              <button v-if="nextLink !== null" @click="showMore()" class="btn btn-main-outline">Показать еще</button>
            </div>
          </template>
          <p v-else>Товары отсутствуют</p>
        </b-tab>
        <b-tab :title="cat.name" v-for="(cat, id) in categories" :key="'category_tab_' + id">
          <template v-if="productsSortByCat[cat.slug].length > 0">
            <b-row>
              <b-col cols="12" sm="6" lg="4" v-for="(prod, index) in productsSortByCat[cat.slug]" :key="index">
                <CardVue :product="prod"></CardVue>
              </b-col>
            </b-row>
            <router-link class="btn btn-main-outline" :to="{name: 'category', params: {categorySlug: cat.slug, catId: id}}">Открыть страницу категории</router-link>
          </template>
          <p v-else>Товары отсутствуют</p>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import CardVue from '@/components/CardVue'

export default {
  components: {
    CardVue
  },
  data () {
    return {
      currentTab: 0,
      categoriesList: null,
      products: null,
      fullListProduct: null,
      productsSortByCat: {},
      newCategoriesList: ['all'],
      loadingCategoryOrProducts: true,
      nextLink: null
    }
  },
  computed: {
    categories () {
      return this.$store.getters.value('categories')
    }
  },
  methods: {
    getProducts () {
      this.loadingCategoryOrProducts = true
      this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products?page_size=10000').then(product => {
        this.products = product.data.results
        if (this.categories !== null && this.categories !== undefined) {
          this.categories.forEach(cat => {
            this.newCategoriesList.push(cat.slug)
          })
          this.newCategoriesList.forEach(catSlug => { // добавляем поля и инициализируем их как массивы
            this.productsSortByCat[catSlug] = []
          })
          this.products.forEach(prod => {
            this.productsSortByCat[prod.category.slug].push(prod)
          })
          // console.log('categories', this.categories)
          // console.log('productsSortByCat', this.productsSortByCat)
          this.loadingCategoryOrProducts = false
          // console.log(this.loadingCategoryOrProducts)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getAllProducts (pageLink) {
      if (this.nextLink === null) {
        this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products?page_size=6').then(product => {
          this.nextLink = product.data.next
          this.fullListProduct = product.data.results
        })
      } else {
        this.axios.get(pageLink).then(product => {
          this.nextLink = product.data.next
          product.data.results.forEach(item => {
            this.fullListProduct.push(item)
          })
        })
      }
    },
    showMore () {
      if (this.nextLink !== null) {
        this.getAllProducts(this.nextLink)
      } else return
    }
  },
  created () {
    this.getAllProducts()
    this.getProducts()
  }  
};
</script>

<style lang="scss">
@import "~@/assets/scss/main";


.portfolio {
  .loader {
    width: 10em;
    height: 10em;
    button {
      position: relative;
      z-index: 2;
    }
  }
}

.nav-tabs {
  border: none;
  .nav-item {
    margin: 0 15px 30px;
  }
  .nav-link {
    color: $grey;
    padding: 10px 25px;
    border: 3px solid $grey;
    border-radius: 0;
    font-size: 18px;
    font-family: $font-family-bold;
    &:hover {
      border-color: $grey;
      background-color: $grey;
      color: #fff;
    }
    &.active {
      background-color: $main-blue;
      border-color: $main-blue;
      color: #fff;
    }
  }
}

.tab-pane {
  text-align: center;
  .btn-main-outline {
    margin-top: 20px;
  }
}

.portfolio {
  &-tabs {
    .nav-link {
      white-space: nowrap;
    }
  }
  &-dropdown {
    display: none;
    margin-bottom: 35px;
    width: 100%;
  }
  .btn-secondary.dropdown-toggle {
    background-color: $main-blue;
  }
  .dropdown {
    &-toggle {
      border-radius: 0;
      font-size: 14px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-menu {
      border-radius: 0;
      font-size: 14px;
    }
    &-item {
      white-space: inherit;
    }
  }
}

@media (max-width: 565px) {
  .portfolio {
    &-tabs {
      .nav-tabs {
        display: none;
      }
    }
    &-dropdown {
      display: block;
    }
  }
}

$scale:2.3em;
$speed:3s;
$easing:cubic-bezier(0.645, 0.045, 0.355, 1);

$bg: #c5f9e2;
$color_1: #ff1668;
$color_2: #1626f5;

.mk-spinner-wrap{
  position:absolute;
  &:last-of-type{z-index:-1;margin:1%}
  animation: rotate $speed linear infinite;
}

.triangle {
	position: relative;
	text-align: left;
  transform-origin: 33.33% 66.66%;
  
  &:before,&:after {
    content: '';
    position: absolute;
    background: inherit;
  }
  
  &,&:before,&:after {
    width:  $scale;
    height: $scale;
    border-top-right-radius: 20%;
  }
  
  &:before{
    transform: 
      rotate(-135deg) 
      skewX(-45deg) 
      scale(1.414,.707) 
      translate(0,-50%)
  }
  
  &:after{
    transform:
      rotate(135deg)
      skewY(-45deg)
      scale(.707,1.414)
      translate(50%)
  }
  
  &:first-of-type{
    background:$color_1;
    transform:
      rotate(-60deg)
      skewX(-30deg)
      scale(1,.866)
      translate(143.33%, 110%);
    animation: first $speed $easing infinite;
  }
  
  &:last-of-type{
    background:$color_2;
    transform:
      rotate(0deg)
      skewX(-30deg)
      scale(1,.866)
      translate(-100%,-4%);
    animation: last $speed $easing infinite;
  }
  
  &.shadow {
    &,&:before,&:after {
      background:darken($bg,8);
      backface-visibility: hidden;
      perspective: 1000;
      filter: blur(0.5em);
    }
  }
}

@keyframes rotate {
  from{transform:rotate(0deg)}
  to{transform:rotate(-360deg)}
}

@keyframes first {
  0%{transform:
      rotate(-60deg)
      skewX(-30deg)
      scale(1,.866)
      translate(143.33%, 110%);}
  33%{transform:
      rotate(-60deg)
      skewX(-30deg)
      scale(1,.866)
      translate(-76.67%, -120%);}
  66%{transform:
      rotate(-60deg)
      skewX(-30deg)
      scale(1,.866)
      translate(-76.67%, 112%);}
}

@keyframes last {
  0%{transform:
      rotate(0deg)
      skewX(-30deg)
      scale(1,.866)
      translate(-100%,-4%);}
  33%{transform:
      rotate(0deg)
      skewX(-30deg)
      scale(1,.866)
      translate(134%,-4%);}
  66%{transform:
      rotate(0deg)
      skewX(-30deg)
      scale(1,.866)
      translate(-89%,-214%);}
}
</style>