<template>
  <div id="app">
    <header>
      <HeaderVue></HeaderVue>
    </header>
    <main class="main">
      <transition name="fade-router">
        <router-view></router-view>
      </transition>
    </main>
    <footer>
      <FooterVue></FooterVue>
      <SubFooterVue></SubFooterVue>
    </footer>
    <transition name="slide">
      <NotificationVue v-if="isNotification"></NotificationVue>
    </transition>
  </div>
</template>

<script>
import HeaderVue from './components/HeaderVue'
import FooterVue from './components/FooterVue'
import SubFooterVue from './components/SubFooterVue'
import NotificationVue from './components/NotificationVue'

export default {
  name: 'App',
  components: {
    HeaderVue,
    FooterVue,
    SubFooterVue,
    NotificationVue
  },
  data () {
    return {
      // scrollFalse: false
    }
  },
  /* methods: {
    onScroll (e) {
      console.log(e)
      this.scrollFalse = true
    }
  }, */
  computed: {
    isNotification () {
      return this.$store.getters.value('isNotification')
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/main';

.fade-enter-active {
  transition: all .3s;
}
.fade-enter {
  opacity: 0;
}

.fade-router-enter-active {
  transition: all 1s;
}
.fade-router-enter {
  opacity: 0;
}

body {
  background-color: #f8f8f8;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1320px) {
  .container {
    max-width: 1260px;
  }
}

.block {
  padding: 50px 0;
}

.form-control {
  background-color: #F8F8F8;
  border-color: transparent;
  border-radius: 0;
  height: 55px;
  margin-bottom: 20px;
  transition: .3s;
  &:focus {
    background-color: #F8F8F8;
    border-color: transparent;
    box-shadow: none;
  }
}

.form-control::-webkit-input-placeholder {
  color: $grey;
}

.form-control::-ms-input-placeholder {
  color: $grey;
}

.form-control::placeholder {
  color: $grey;
}

a {
  color: $main-blue;
  transition: .3s;
  &:hover {
    color: #095179;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-headings;
  color: $headings;
}

body {
  font-family: $font-family-base!important;
  color: $text;
}

.tns-controls {
  &:focus {
    outline: none
  }
}

.btn {
  &:active,
  &:focus {
    box-shadow: none;
  }
}

.section-heading {
  text-align: center;
  font-size: 44px;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    font-size: 38px;
  }
  @media (max-width: 767px) {
    font-size: 34px;
  }
  @media (max-width: 567px) {
    font-size: 30px;
  }
}

.btn-main {
  background-color: $main-blue;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid transparent;
  // font-family: $font-family-bold;
  color: #fff;
  font-size: 14px;
  padding: 16px 50px 17px;
  &:hover {
    background-color: #095179;
    border-color: #095179;
    color: #fff;
  }
  &:active {
    background-color: #00283F;
  }
  @media screen and (max-width: 1319px) {
    padding-right: 35px;
    padding-left: 35px;
  }
}

.btn-main-outline {
  border: 2px solid $main-blue;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
  padding: 16px 40px 17px;
  color: $main-blue;
  font-family: $font-family-bold;
  &:hover {
    background-color: #095179;
    color: #fff;
    border-color: #095179;
  }
  @media screen and (max-width: 1319px) {
    padding-right: 35px;
    padding-left: 35px;
  }
}

.breadcrumb {
  background-color: transparent;
  padding: 30px 0;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 14px;
  &s {
    background-color: #fff;
  }
  &-item {
    font-family: 'OpenSans_B';
    &.active {
      font-family: 'OpenSans_R';
    }
    & + &::before {
      content: ">";
      padding-right: 3px;
    }
    & + & {
      padding-left: 3px;
    }
  }
}
.tns-controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 2;
  button {
    position: relative;
    border: none;
    width: 40px;
    height: 40px;
    border: 1px solid #656565;
    background: rgba(236, 236, 236, 0.5);
    transition: .3s;
    &:hover {
      background: rgba(236, 236, 236, 1);
    }
    &:after {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #656565;
      font-size: 26px;
    }
    &:first-child:after {
      content: "\2190";
    }
    &:last-child:after {
      content: "\2192";
    }
  }
  button:disabled {
    opacity: 0;
  }
}
</style>
