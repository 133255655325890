import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTinySlider from 'vue-tiny-slider'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuelidate from 'vuelidate'
import Vuex from 'vuex'
import router from './router'
import store from './storages'
import VueKinesis from 'vue-kinesis'
import VueSimpleSVG from 'vue-simple-svg'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import App from './App.vue'

// Vue.use(VueReCaptcha, { siteKey: '6LcDs6oaAAAAAFxSE3wG-mNpvhLRNcJDbVqIHvAD' })

Vue.use(VueSimpleSVG)
Vue.use(VueKinesis)
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.component('tiny-slider', VueTinySlider)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
