<template>
  <div class="contacts-page">
    <BreadCrumbsVue></BreadCrumbsVue>
    <ContactsInfoVue></ContactsInfoVue>
    <MapBlockVue></MapBlockVue>
    <FeaturesVue></FeaturesVue>
    <InfoBlockVue></InfoBlockVue>
  </div>
</template>

<script>
import BreadCrumbsVue from '../BreadCrumbsVue'
import ContactsInfoVue from './ContactsInfoVue'
import MapBlockVue from './MapBlockVue'
import FeaturesVue from '../FeaturesVue'
import InfoBlockVue from '../InfoBlockVue'

export default {
  components: {
    ContactsInfoVue,
    MapBlockVue,
    BreadCrumbsVue,
    FeaturesVue,
    InfoBlockVue
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters.value('breadcrumbs')
    }
  },
  methods: {
    setBread () {
      this.$store.dispatch('SET_VALUE', {key: 'breadcrumbs', value: [
        {
          text: 'Главная',
          to: '/'
        },
        {
          text: 'Контакты',
          active: true
        },
      ]})
    }
  },
  mounted () {
    this.setBread()
  }
}
</script>

<style>

</style>