<template>
  <div class="footer">
    <b-container>
      <div class="footer__row">
        <div class="footer-wrap">
          <div class="footer__block footer-1">
            <img src="@/assets/images/logo-footer.svg" alt="" />
            <span>© Interior Plus <br />Все права защищены</span>
          </div>
          <div class="footer__block footer-2">
            <div class="social-block">
              <span>Давайте дружить <br>в соц. сетях:</span>
              <!-- <a href="https://instagram.com/interior.plus_" target="_blank">
                <img src="@/assets/images/inst.svg" alt="">
              </a> -->
              <a href="https://vk.com/public201641850" target="_blank">
                <img src="@/assets/images/vk.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="footer__block footer-3">
          <span>Закажите бесплатный расчёт <br>стоимости:</span>
          <button @click="openModal()" class="btn btn-main">Получить расчет</button>
        </div>
      </div>
    </b-container>
    <transition name="fade">
      <PopupVue
        v-if="isPopup"
        :buttonText="'Получить расчет'"
        @closePopup="isPopup = false"
        :titlePopup="'Заявка на расчёт стоимости'"
      >
        <template #title>
          <h2>Заявка на расчёт стоимости</h2>
        </template>
      </PopupVue>
    </transition>
  </div>
</template>

<script>
import PopupVue from "./PopupVue"

export default {
  components: {
    PopupVue,
  },
  data() {
    return {
      isPopup: false,
    }
  },
  methods: {
    openModal() {
      this.isPopup = true;
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/main";

.footer {
  // margin-top: 50px;
  padding: 35px 0;
  background-color: $main-blue;
  font-size: 14px;
  color: #fff;
  &__row {
    display: flex;
    justify-content: space-between;
  }
  &__block {
    display: flex;
    align-items: center;
    img {
      display: block;
    }
  }
  &-wrap {
    display: flex;
  }
}

.footer-1 {
  span {
    white-space: nowrap;
  }
  img {
    margin-right: 35px;
  }
}

.footer-2 {
  margin-left: 75px;
  .social-block {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, .1);
    padding: 10px 20px;
    border-radius: 2px;
    a {
      display: inline-block;
      margin-left: 15px;
    }
    img {
      margin-bottom: 0;
    }
  }
}

.footer-3 {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    height: 50px;
    left: -40px;
    width: 1px;
    background-color: rgba(81, 128, 170, 0.44);
  }
  span {
    display: inline-block;
    margin-right: 30px;
  }
  .btn-main {
    background-color: #fff;
    color: $main-blue;
  }
}

@media screen and (max-width: 1319px) {
  .footer {
    &__nav {
      font-size: 15px;
    }
    &-1 {
      max-width: 155px;
      flex-wrap: wrap;
      img {
        max-width: 105px;
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    &-3 {
      max-width: 395px;
      &:after {
        left: -30px;
      }
      span {
        display: inline-block;
        max-width: 40%;
        margin-right: 25px;
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &-2 {
      max-width: 150px;
      .social-block {
        flex-wrap: wrap;
        padding: 15px;
        span {
          flex-basis: 100%;
          margin-bottom: 10px;
        }
        a {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }
    &-3 {
      flex-wrap: wrap;
      max-width: 230px;
      span {
        flex-basis: 100%;
        max-width: none;
      }
      &:after {
        height: 120px;
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &-wrap {
      flex-wrap: wrap;
    }
    &-1 {
      max-width: none;
      img {
        margin-bottom: 0;
      }
      span {
        margin-left: 15px;
      }
    }
    &-2 {
      margin-top: 20px;
      margin-left: 0;
      max-width: none;
      .social-block {
        padding: 10px 20px;
        span {
          flex-basis: auto;
          margin-bottom: 0;
        }
        a {
          margin-left: 15px;
          margin-right: 0;
        }
      }
    }
    &-3 {
      max-width: 205px;
      .btn-main {
        padding-right: 30px;
        padding-left: 30px;
      }
      &:after {
        left: -22px;
      }
    }
  }
}

@media (max-width: 575px) {
  .footer {
    &__row {
      flex-wrap: wrap;
    }
    &-wrap {
      justify-content: center;
    }
    &-3 {
      justify-content: center;
      margin-top: 25px;
      text-align: center;
      max-width: none;
      flex-basis: 100%;
      span {
        margin-right: 0;
        margin-bottom: 10px;
      }
      &:after {
        display: none;
      }
    }
  }
}
</style>