import Vue from 'vue'
import Router from 'vue-router'
import MainPageWrapperVue from '../components/MainPage/MainPageWrapperVue'
import ContactsPageWrapperVue from '../components/ContactsPage/ContactsPageWrapperVue'
import PortfolioPageWrapperVue from '../components/PortfolioPage/PortfolioPageWrapperVue'
import CategoriesPageWrapperVue from '../components/CategoriesPage/CategoriesPageWrapperVue'
import Vue404 from '../components/Vue404'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      name: 'main',
      path: '/',
      component: MainPageWrapperVue
    },
    {
      name: 'contacts',
      path: '/contacts',
      component: ContactsPageWrapperVue
    },
    {
      name: 'category',
      path: '/category/:categorySlug',
      component: CategoriesPageWrapperVue
    },
    {
      name: 'cardDetail',
      path: '/:categorySlug/:productId',
      props: true,
      component: PortfolioPageWrapperVue
    },
    {
      name: 'admin',
      path: '/admin'
    },
    {
      name: 'error',
      path: '*',
      component: Vue404
    }
  ],
  mode: 'history',
  scrollBehavior () {
    return new Promise((resolve) => {
      resolve({ x: 0, y: 0, behavior: 'smooth' })
    })
  }
})

export default router
