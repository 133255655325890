<template>
  <div class="other-works">
    <b-container>
      <h2 class="section-heading">Другие работы</h2>
      <b-row>
        <b-col cols="12" sm="6" lg="4" v-for="(work, index) in otherWorks" :key="index">
          <div class="other-works__block">
            <CardVue @click="getOtherProducts()" :product="work"></CardVue>
          </div>
        </b-col>
      </b-row>
      <div class="text-center">
        <button v-if="nextLink !== null" @click="showMore()" class="btn btn-main-outline">Показать еще</button>
      </div>
    </b-container>
  </div>
</template>

<script>
import CardVue from '@/components/CardVue'

export default {
  components: {
    CardVue
  },
  data () {
    return {
      otherWorks: null,
      nextLink: null
    }
  },
  methods: {
    getOtherProducts () {
      if (this.nextLink === null) {
        this.axios.get(process.env.VUE_APP_HOST + '/api/v1/products/?category__slug=' + this.$route.params['categorySlug'] + '&page_size=3').then(resp => {
          this.nextLink = resp.data.next
          this.otherWorks = resp.data.results
        })
      } else {
        this.axios.get(this.nextLink).then(resp => {
          this.nextLink = resp.data.next
          resp.data.results.forEach(item => {
            this.otherWorks.push(item)
          })
        })
      }
    },
    showMore () {
      if (this.nextLink !== null) {
        this.getOtherProducts(this.nextLink)
      } else return
    }
  },
  mounted () {
    this.getOtherProducts()
  }
}
</script>

<style lang="scss">
.other-works {
  padding: 100px 0 50px;
}
</style>